import { SET_VEHICLETYPE } from '../actions';

const vehicletype = (state = [], action) => {
  switch (action.type) {
	case SET_VEHICLETYPE:
      return [...action.value];  
    default: return state;
  }
}

export default vehicletype;
