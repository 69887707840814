import { SET_DELETEVEHICLE } from '../actions';

const deletevehicle = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEVEHICLE:
      return [...action.value];  
    default: return state;
  }
}

export default deletevehicle;
