/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDCOMPANY, SET_ADDCOMPANY, GET_COMPANYLIST,SET_COMPANYLIST,GET_EDITCOMPANY,SET_EDITCOMPANY,GET_DELETECOMPANY, SET_DELETECOMPANY } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

export const watchGetCompanyList = function* () {
  yield takeEvery(GET_COMPANYLIST, workerGetCompanyList);
}

function* workerGetCompanyList({ CompanyId }) {
  if(CompanyId == undefined){
      var url = apiUrl+'company/list';
  }else{
      var url = apiUrl+'company/list?id='+CompanyId;
  }
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_COMPANYLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}


/*save the company*/

export const watchGetAddCompany = function* () {
  yield takeEvery(GET_ADDCOMPANY, workerGetAddCompany);
}

function* workerGetAddCompany({ formPayload }) {
  try {
      
 const result = yield call(getAddCompany, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDCOMPANY, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddCompany(formPayload) {
   return Axios.post(apiUrl+'company/add', formPayload);
} 


/*edit the company*/

export const watchGetEditCompany = function* () {
  yield takeEvery(GET_EDITCOMPANY, workerGetEditCompany);
}

function* workerGetEditCompany({ formPayload }) {
  try {
      
 const result = yield call(getEditCompany, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITCOMPANY, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditCompany(formPayload) {
   return Axios.post(apiUrl+'company/edit', formPayload);
}