import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

import Geocode from "react-geocode";
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";


import {GET_EDITEVENT,GET_EVENTDETAIL,GET_EVENTLIST}  from '../../actions'; 
import {apiUrl } from "../../components/Config/Config";
const axios = require("axios");

class Edit extends Component {
	
	constructor(props)
	{
		
		super(props);	
	    const eventId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		
		this.state = {
			users:[],
			eventdetails:'',
			event_name:'',			
			event_description:'',
			event_location:'',
			event_start_date:'',
			event_end_date:'',
			event_status:'',
			event_type:'',
			e_start_point:'',
			e_end_point:'',
			no_of_passengers:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			event_id: eventId,
			Loading:false,
			admin_id: localStorage.getItem('admin_id'),
			selectedRoute:'',
			routevalue:'',
			routelist:[],
			selectedDriver:'',
			drivervalue:'',
			driverlist:[],
			selectedBus:'',
			busvalue:'',
			buslist:[],
			selectedGroup:'',
			groupvalue:'',
			grouplist:[],
			selectedHelper:'',
			helpervalue:'',
			helperlist:[],
		     routes : [],
			 routes_list:[]

		};
		this.props.getEventDetail(eventId, localStorage.getItem('admin_id'));
		
		var qs = require('qs');
			var postObject = {
			id: eventId
			};
			
			axios.post(apiUrl+"event/fronteventdetail",qs.stringify(postObject)).then(res => {
				if(res.data.status === "success"){	
		
				this.setState({eventdetails: res.data.eventlist[0]});

				//this.setState({event_name:formdata.event_name});
				if(res.data.eventlist[0].route_lists!='undefined'){
					

					this.setState({route_lists: res.data.eventlist[0].route_lists});
					}
					//console.log('routes',routes);	
				//console.log("test44444",this.state.eventdetails)	
				
				}else{	
				//console.log("test")	
				}	
				});

    }
	


	componentDidMount() {
	
		document.title = PageTitle('Event Edit');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
			}
			
			
    }



    componentWillReceiveProps(Props){
	
	
		
    }

	
  render() {
	const {eventdetails,route_lists} = this.state;

	
	let routes = '';
  	if(route_lists !== undefined){
		 routes = route_lists.map((values, index) =>{
			return<li>{values.route_name}</li>	
		 });
		 
	} 
	console.log('routes_list',routes);
	   return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="event" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="listing-header">
                <div className="title">
				<h4>View Details</h4>
                </div>
                <div className="search-list">
                </div>
                <div className="add_button">  
                    <a className="btn btn_orange btn_minwid animate-btn2" href='/event'>Back</a> 
                </div>  
              </div>
			 
			<div className="form-row">
			<div className="form-left">
			
			<p><strong>Event Name : </strong>{eventdetails.event_name}</p>
	        <p><strong>Description  : </strong>{eventdetails.event_description}</p>
			<p><strong>Start Date : </strong>{eventdetails.event_start_date}</p>
			<p><strong>Start Location : </strong>{eventdetails.e_start_point}</p>
		    <p><strong>Assigned Bus : </strong>{eventdetails.car_name}</p>
		    <p><strong>Assigned Driver : </strong>{eventdetails.drivername}</p>
		    <p><strong>Assigned Helper : </strong>{eventdetails.helper_username}</p>	
			</div>
			<div className="form-right">	
			
			<p><strong>Event Place : </strong>{eventdetails.event_location}</p>
			<p><strong>Event Type : </strong>{eventdetails.event_type}</p>
			<p><strong>End Date : </strong>{eventdetails.event_end_date}</p>
			<p><strong>End Location : </strong>{eventdetails.e_end_point}</p>
			<p><strong>No of People : </strong>{eventdetails.no_of_passengers}</p>
			<p><strong>Assigned Route : </strong><ul>{routes}</ul></p>
			<p><strong>Assigned Group : </strong>{eventdetails.group_name}</p>
			

				
			</div>
			</div>	
			
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	eventdetail  : state.eventdetail,
    eventedit: state.eventedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
	getEventDetail: (eventId, adminId) =>{
		dispatch({ type: GET_EVENTDETAIL,eventId, adminId});
	 },
     getEditEvent: (formPayload) =>{
       dispatch({ type: GET_EDITEVENT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));