 /*local */

// export const apiUrl      = "http://localhost/busproject/api2/";
// export const baseUrl      = "http://localhost/busproject";
// export const carImageUrl      = "http://localhost/busproject/media/cars";
// export const sampleFileUrl      = "http://localhost/busproject/media/sample_file/";
// export const licenseURL       = "http://localhost/busproject/media/driverlicense";
// export const insuranceURL       = "http://localhost/busproject/media/driverinsurance";
// export const profileURL       = "http://localhost/busproject/media/driverprofile";
// export const drivercarURL       = "http://localhost/busproject/media/drivercarimage";

/*Live */


export const apiUrl           = "https://bus-c.uxt.design/busbooking/api2/";
export const baseUrl          = "https://bus-c.uxt.design/busbooking";
export const carImageUrl      = "https://bus-c.uxt.design/busbooking/media/cars";
export const licenseURL       = "https://bus-c.uxt.design/busbooking/media/driverlicense";
export const insuranceURL     = "https://bus-c.uxt.design/busbooking/media/driverinsurance";
export const profileURL       = "https://bus-c.uxt.design/busbooking/media/driverprofile";
export const drivercarURL     = "https://bus-c.uxt.design/busbooking/media/drivercarimage";
export const sampleFileUrl    = "https://bus-c.uxt.design/busbooking/media/sample_file/";


