import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import 'mapbox-gl/dist/mapbox-gl.css'; 
import mapboxgl from "mapbox-gl";
import { GET_VIEWONGOINGRIDE } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const rideId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      rideId: rideId,
      ongoingrideview:'',
      centerlat:40.730610,
      centerlong:-73.935242,
      from_long:'',
      from_lat:''
    };

    mapboxgl.accessToken = 'pk.eyJ1Ijoib2ZlbCIsImEiOiJjazI4Y3FvdTgwejgzM25xaGMxNHkyM3lyIn0.iPMeYOlpDFigB_K-EigwrQ';

    var qs = require('qs');
    var postObject = {             
    id   :rideId
    };
    this.props.getViewOngoingride(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('Ongoing View');
   }

   componentDidUpdate(prevProps,prevState){
    
    if(this.state.ongoingrideview.e_start_long!=='' && this.state.ongoingrideview.e_start_lat!==''){

      var long = this.state.ongoingrideview.e_start_long;
      var lat = this.state.ongoingrideview.e_start_lat;

      var map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center:[this.state.centerlong, this.state.centerlat],
      zoom: 8
      });

      map.on('load', () => {
      var marker = new mapboxgl.Marker()
      .setLngLat([lat, long])
      .addTo(map);
      });
    }
    
   }

   componentWillReceiveProps(Props){
      if(Props.ongoingrideview!==this.state.ongoingrideview){
        if(Props.ongoingrideview[0].status == "success"){
          this.setState({ongoingrideview: Props.ongoingrideview[0].eventlist[0]}) 
        }
      }

   }
  
  render() {
    console.log(this.state.ongoingrideview)
    return (
      <div className="wrapper"> 
      <Header />
      <MenuSidebar currentpage="ongoingride" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Ongoing View</h3>
                </div>
                <div className="form-row ongoing-wrapper">
                    <div className="form-left">
                      <p><b>Driver name: </b>{this.state.ongoingrideview.drivername}</p>
                      <p><b>Driver number: </b>{this.state.ongoingrideview.drivermobile}</p>          
                      <p><b>Driver Email: </b>{this.state.ongoingrideview.driveremail}</p>
                    </div>      
                    <div className="form-right">
                      <p><b>Event name: </b>{this.state.ongoingrideview.event_name}</p>
                      <p><b>Transport name: </b>{this.state.ongoingrideview.car_name}</p>
                      <p><b>From location: </b>{this.state.ongoingrideview.e_start_point}</p>
                      <p><b>To location: </b>{this.state.ongoingrideview.e_end_point}</p>
                    </div>  
                    </div>  
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    ongoingrideview: state.ongoingrideview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getViewOngoingride: (rideId) =>{
       dispatch({ type: GET_VIEWONGOINGRIDE,rideId });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));