import { SET_VIEWONGOINGRIDE } from '../actions';

const ongoingrideview = (state = [], action) => {
  switch (action.type) {
	case SET_VIEWONGOINGRIDE:
      return [...action.value];  
    default: return state;
  }
}

export default ongoingrideview;
