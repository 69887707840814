import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { licenseURL, insuranceURL, profileURL, drivercarURL, apiUrl } from '../Config/Config';
import {GET_LOCATEDDRIVERS }  from '../../actions'; 
import mapboxgl from "mapbox-gl";
const testData = require('../../common/sample_file/sample_located_drivers.json');


class LocateDrivers extends Component {
	constructor(props)
	{
		super(props);
		mapboxgl.accessToken = 'pk.eyJ1Ijoib2ZlbCIsImEiOiJjazI4Y3FvdTgwejgzM25xaGMxNHkyM3lyIn0.iPMeYOlpDFigB_K-EigwrQ';

		var qs = require('qs');
        var postObject_loc = {
          admin_id : localStorage.getItem("admin_id")
        };
     
		this.props.getLocatedDrivers(qs.stringify(postObject_loc));
		
	}
	componentDidMount(){

		var map = new mapboxgl.Map({
		  container: 'map',
		  style: 'mapbox://styles/ofel/ck28cs1ua2wbe1ckvmyycnow3',
		  center: [-73.989,40.733],
		  zoom: 7
		});
		map.addControl(new mapboxgl.NavigationControl());
		var long_lat_arr = [
		"-73.989,40.733",
		"-73.70846449999999,40.6642699",
		"-120.740135,47.751076"
			];
			var long_lat_arr1 = [];
			var driver_name_arr1 = [];
	/*Get the driver details from db*/
var qs = require('qs');	
var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
	axios.post(apiUrl+"driver/locateddrivers",qs.stringify(postObject)).then(res => {
		if(res.data.status === "success"){	
			//console.log(res.data);
			res.data.driverlist.forEach(function(arr){
				if(arr.drivers_lat != null && arr.drivers_long != null){
					long_lat_arr1.push(arr.drivers_long+","+arr.drivers_lat);
					driver_name_arr1.push(arr.firstname+" "+arr.lastname);
				}
				
			})
		}else{	
			console.log("Something went wrong!")	
		}	
	});			
//console.log(long_lat_arr1,"long_lat_arr1");
//console.log(driver_name_arr1,"driver_name_arr1");
setTimeout(function(){ 
	long_lat_arr1.forEach(function(arr,i){
		//console.log(arr,"test")
		axios.get("https://api.mapbox.com/geocoding/v5/mapbox.places/"+arr+".json?types=poi&access_token=pk.eyJ1Ijoib2ZlbCIsImEiOiJjazI4Y3FvdTgwejgzM25xaGMxNHkyM3lyIn0.iPMeYOlpDFigB_K-EigwrQ")
		  .then(res => {
			  if(res.data.features != ''){
				  var el = document.createElement('div');
				  el.className = 'marker';
				  // make a marker for each feature and add to the map
				  new mapboxgl.Marker(el)
					.setLngLat(res.data.features[0].geometry.coordinates)
					.setPopup(new mapboxgl.Popup({ offset: 25 })
					.setHTML('<p><strong>' + driver_name_arr1[i] +"</strong>,<br/>"+res.data.features[0].place_name + '</p>'))
					.addTo(map);
			  }
		  })
		  
		
	});
},1500);

		/*var geojson = testData;

		// add markers to map
		geojson.features.forEach(function(marker) {
console.log(marker,"tester marker");
		  // create a HTML element for each feature
		  var el = document.createElement('div');
		  el.className = 'marker';

		  // make a marker for each feature and add to the map
		  new mapboxgl.Marker(el)
			.setLngLat(marker.geometry.coordinates)
			.setPopup(new mapboxgl.Popup({ offset: 25 })
			.setHTML('<p>' + marker.properties.title + '</p>'))
			.addTo(map);
		});
		*/
		

	}


	componentWillReceiveProps(Props){ 
		console.log(Props)
      /*if(Props.ongoingrideview!==this.state.ongoingrideview){
        if(Props.ongoingrideview[0].status == "success"){
          this.setState({ongoingrideview: Props.ongoingrideview[0].driver_data[0]}) 
        }
      }*/
   }

	 render() {
		return (
			<div className="wrapper"> 
	
			<Header />
			<MenuSidebar currentpage="locatedrivers" />
			<div className="content">	
				<div className="content-wrapper">
					<div className="form-wrapper">
					<div className="success_message"></div>
						<div className="title">
							<h4>Locate Drivers</h4>
							<p id="demo"></p>
						</div>
						<div className="map-wrapper">
							<div ref={el => this.mapContainer = el} className="mapContainer" id="map"/>
						</div>
					</div>
				</div>
			</div>
			</div>
		);
	 }
}

const mapStateToProps = (state) => {
	return {
		locateddrivers : state.locateddrivers
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getLocatedDrivers : (formPayload) => { 
			dispatch({ type: GET_LOCATEDDRIVERS,formPayload});
		}		
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LocateDrivers));