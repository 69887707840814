import { SET_ADDROUTE } from '../actions';

const routeadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDROUTE:
      return [...action.value];  
    default: return state;
  }
}

export default routeadd;
