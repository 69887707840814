import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { GET_GROUPLIST,GET_DELETEGROUP  } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      grouplist:''
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id
    };
    this.props.getGroupList(qs.stringify(postobject));
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
    componentDidMount() {
      document.title = PageTitle('Group List');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id
     };
     this.props.getGroupList(qs.stringify(postobject))
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id         
  };
  //console.log(postObject);
  this.props.getGroupList(qs.stringify(postObject));

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.grouplist).length > 0) {
      if (Props.grouplist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.grouplist[0].records_count, grouplist: Props.grouplist[0]["grouplist"] });
      }
    }

    if (Object.keys(Props.deletegroup).length > 0) {
      if (Props.deletegroup[0]["status"] === "success") {
        this.setState({
          grouplist: Props.deletegroup[0]["grouplist"],
        });
      }

      this.setState({statusmessage: Props.deletegroup[0].message});

            setTimeout(
            function() {
              this.setState({statusmessage:''})
            }
            .bind(this),
            3000
          );
     window.location.reload(); 
    }
     
   }

   deletegroup(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      group_id  :id
    };
    console.log(postObject);
  this.props.getDeleteGroup(qs.stringify(postObject));
}

grouplist() {
    var grouplist = this.props.grouplist[0];
    if (grouplist != "undefined" && grouplist != null) {
      if (grouplist.status == "success") {
        if (Object.keys(grouplist).length > 0) {
          const grouplistDetails = grouplist.grouplist.map(
            (grouplist, Index) => {
              let sno = Index+1;
              if(grouplist.group_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              var istyle = { width: 100};
              return (
                <tr key={grouplist.group_id}>
					<td>{sno}</td>
					<td>
					{grouplist.group_name}
					</td>   
                   <td>{status}</td>
                  <td className="actiongroup">

                    <Link to={{ pathname: '/group-edit/'+grouplist.group_id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.deletegroup.bind(this,grouplist.group_id)} className="" title="Duplicate">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return grouplistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="8" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="8" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="group" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Groups</h3>
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="add" href='/group-add'>Add</a> 
                </div>  
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			          <th>Group Name</th>
                <th>Status</th>
                <th>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.grouplist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    grouplist: state.grouplist,
    deletegroup: state.deletegroup,
   

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getGroupList: (formVal) =>{
      dispatch({ type: GET_GROUPLIST, formVal});
   },
   getDeleteGroup: (formPayload) =>{
     dispatch({ type: GET_DELETEGROUP, formPayload });
   },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));