import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { GET_HELPERLIST,GET_DELETEHELPER  } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      helperlist:''
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id
    };
    this.props.getHelperList(qs.stringify(postobject));
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
    componentDidMount() {
      document.title = PageTitle('Helper List');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id
     };
     this.props.getHelperList(qs.stringify(postobject))
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id         
  };
  //console.log(postObject);
  this.props.getHelperList(qs.stringify(postObject));

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.helperlist).length > 0) {
      if (Props.helperlist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.helperlist[0].records_count, helperlist: Props.helperlist[0]["helperlist"] });
      }
    }

    if (Object.keys(Props.deletehelper).length > 0) {
      if (Props.deletehelper[0]["status"] === "success") {
        this.setState({
          helperlist: Props.deletehelper[0]["helperlist"],
        });
      }

      this.setState({statusmessage: Props.deletehelper[0].message});

            setTimeout(
            function() {
              this.setState({statusmessage:''})
            }
            .bind(this),
            3000
          );
     window.location.reload(); 
    }
     
   }

   deletehelper(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      helper_id  :id
    };
    console.log(postObject);
  this.props.getDeleteHelper(qs.stringify(postObject));
}

helperlist() {
    var helperlist = this.props.helperlist[0];
    if (helperlist != "undefined" && helperlist != null) {
      if (helperlist.status == "success") {
        if (Object.keys(helperlist).length > 0) {
          const helperlistDetails = helperlist.helperlist.map(
            (helperlist, Index) => {
              let sno = Index+1;
              if(helperlist.helper_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              var istyle = { width: 100};
              return (
                <tr key={helperlist.helper_id}>
					<td>{sno}</td>
					<td>
					{helperlist.helper_username}
					</td>                 
					<td>{helperlist.helper_fname}</td>
					<td>{helperlist.helper_lname}</td>
				
					<td>{helperlist.helper_email_address}</td>
					<td>{helperlist.helper_contact}</td>
                   <td>{status}</td>
                  <td className="actiongroup">

                    <Link to={{ pathname: '/helper-edit/'+helperlist.helper_id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.deletehelper.bind(this,helperlist.helper_id)} className="" title="Duplicate">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="8" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="8" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="helper" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Helpers</h3>
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="add" href='/helper-add'>Add</a> 
                </div>  
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			          <th>User Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
				        <th>Contact</th>
                <th>Status</th>
                <th>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.helperlist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    helperlist: state.helperlist,
    deletehelper: state.deletehelper,
   

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getHelperList: (formVal) =>{
      dispatch({ type: GET_HELPERLIST, formVal});
   },
   getDeleteHelper: (formPayload) =>{
     dispatch({ type: GET_DELETEHELPER, formPayload });
   },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));