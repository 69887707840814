import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

import Geocode from "react-geocode";
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";


import {GET_EDITROUTE,GET_ROUTEDETAIL,GET_ROUTELIST}  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		
		super(props);	
	    const routeId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			route_name:'',
			route_status:'',
			r_start_point:'',
			r_end_point:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			route_id: routeId,
			Loading:false,
			admin_id: localStorage.getItem('admin_id')

		};
		this.props.getRouteDetail(routeId);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");
		

		

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		const google = window.google;
		const callfunc = this;
		console.log(name)
			   if(name == 'r_start_point'){
					   var input = document.getElementById('from_loc');
					   var autocomplete = new google.maps.places.Autocomplete(input);
					   autocomplete.addListener('place_changed',function() {
					   document.getElementById("location-error").style.display = 'none';
					   var place = autocomplete.getPlace();
					   console.log('place',place)
					   var locationAddress1 = place.name;
					   var locationAddress = place.formatted_address;
					   var fullplace = place.name+','+locationAddress;
					   callfunc.setState({r_start_point: locationAddress})
					   callfunc.getgoecoder(locationAddress,input.name);
					   callfunc.setState({fulldroploc : fullplace});
					   });
			   }

			   if(name == 'r_end_point'){
				
				var input = document.getElementById('to_loc');
				var autocomplete = new google.maps.places.Autocomplete(input);
				autocomplete.addListener('place_changed',function() {
				document.getElementById("location-error").style.display = 'none';
				var place = autocomplete.getPlace();
				var locationAddress1 = place.name;
				var locationAddress = place.formatted_address;
				var fullplace = place.name+','+locationAddress;
				callfunc.setState({r_end_point: locationAddress})
				callfunc.getgoecoder(locationAddress,input.name);
				callfunc.setState({fulldroploc : fullplace});
				});
		}

	

			
    }

			
	getgoecoder(input, name){
		$('#from_loc').val('');

		$('#to_loc').val('');

			
		if(name == 'r_start_point'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;
				this.setState({from_long : lng, from_lat: lat, r_start_point:response.results[0].formatted_address});
				
				document.getElementById('from_loc').value = response.results[0].formatted_address;
				$('#from_loc').val(response.results[0].formatted_address);					  
			},
			error => {
				console.error(error);
			  }
			);
		}else if(name == 'r_end_point'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					to_long : lng,
					to_lat: lat,
					r_end_point: response.results[0].formatted_address
				});	
				
				document.getElementById('to_loc').value = response.results[0].formatted_address;
				$('#to_loc').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}

	}
   keyPressed(event) {
	 if (event.key === "Enter") {
	 localStorage.setItem('setkeyword',event.target.value);
	  setTimeout(() => {
			$('#clickToggle').trigger('click');
			document.getElementById("from_loc").value = "";
		 }, 500);
	}
	}

	componentDidMount() {
		document.title = PageTitle('Route Edit');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
			}
    }

	


        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					route_id : formPayload.route_id,
					route_name:formPayload.route_name,
					r_start_point:formPayload.r_start_point,
					r_end_point:formPayload.r_end_point,
					to_long:formPayload.to_long,
					to_lat:formPayload.to_lat,
					from_lat:formPayload.from_lat,
					from_long:formPayload.from_long,
					route_status:status,
				};
				//console.log(postObject);
				this.props.getEditRoute(qs.stringify(postObject));
			}
	}


	
	handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			 const config = {
				headers: {
				  'Content-Type': 'multipart/form-data'
				}
			  };

			const formPayload = this.state;
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}
			
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				route_id : formPayload.route_id,
				route_name:formPayload.route_name,
					r_start_point:formPayload.r_start_point,
					r_end_point:formPayload.r_end_point,
					to_long:formPayload.to_long,
					to_lat:formPayload.to_lat,
					from_lat:formPayload.from_lat,
					from_long:formPayload.from_long,
					route_status:status
			};
			let formData = new FormData();
			for(let k in postObject) {
				formData.append(k, postObject[k]);
			}
				       

			this.props.getEditRoute(formData,config);
		
		}
}


	validateForm() {
		
		const {route_name,r_end_point,r_start_point} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		  if (!route_name) {
			formIsValid = false;
			$('.errorroute_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(route_name){
			$('.errorroute_name').html('');
		}
		
	
		if (!r_start_point) {
			formIsValid = false;
			$('.errorr_start_point').html('<span class="errorspan">Please fill the field</span>');
		}else if(r_start_point){
			$('.errorr_start_point').html('');
		}

		// if (!r_end_point) {
		// 	formIsValid = false;
		// 	$('.errorr_end_point').html('<span class="errorspan">Please fill the field</span>');
		// }else if(r_end_point){
		// 	$('.errorr_end_point').html('');
		// }


		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.routedetail !== this.props.routedetail){
    		if(Object.keys(Props.routedetail).length > 0){
				this.setState({Loading:false});
				//console.log(Props.routedetail);
					if(Props.routedetail[0].status === "success"){

						const formdata = Props.routedetail[0].routelist;
						if(formdata.route_status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						
						this.setState({route_name:formdata.route_name});
						
						this.setState({r_start_point: formdata.r_start_point});
						this.setState({r_end_point: formdata.r_end_point});
						
						this.setState({to_long: formdata.r_end_long});
						this.setState({to_lat: formdata.r_end_lat});
						this.setState({from_lat: formdata.r_start_lat});
						this.setState({from_long: formdata.r_start_long});
						
						
					}
    		}
    	}
    	if(Props.routeedit !== this.props.routeedit){
    		if(Object.keys(Props.routeedit).length > 0){
				if(Props.routeedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.routeedit[0].routelist;
				if(formpayload.route_status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({route_name:formpayload.route_name});
						this.setState({r_start_point: formpayload.r_start_point});
						this.setState({r_end_point: formpayload.r_end_point});
						
						this.setState({to_long: formpayload.r_end_long});
						this.setState({to_lat: formpayload.r_end_lat});
						this.setState({from_lat: formpayload.r_start_lat});
						this.setState({from_long: formpayload.r_start_long});
						
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.routeedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.routeedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	
  render() {
  	  const {selectedOption} = this.state;
		//console.log('aafff',this.state);
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="route" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Route Name:</label>
				    <input type="text" name="route_name" onChange={this.handleInputChange} className="form-control" value={this.state.route_name} />
					<div className="errorroute_name"></div>
				</div>

				<div className="form-group">
					<label>Route  Location:</label>
				    <input id="from_loc" placeholder="Enter a  location" type="text" name="r_start_point" onChange={this.handleInputChange} className="form-control" value={this.state.r_start_point} />
					<div className="errorr_start_point	"></div>
				</div>
				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>

				
			{/*	<div className="form-group">
					<label>Route End Location:</label>
				    <input id="to_loc" placeholder="Enter a End location" type="text" name="r_end_point" onChange={this.handleInputChange} className="form-control" value={this.state.r_end_point} />
					<div className="errorr_end_point	"></div>
	             </div>  */}
				
			</div>
			<div className="form-right">
			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	routedetail  : state.routedetail,
    routeedit: state.routeedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
	getRouteDetail: (routeId) =>{
		dispatch({ type: GET_ROUTEDETAIL,routeId });
	 },
     getEditRoute: (formPayload) =>{
       dispatch({ type: GET_EDITROUTE, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));