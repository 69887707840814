import { SET_CARLIST } from '../actions';

const carlist = (state = [], action) => {
  switch (action.type) {
	case SET_CARLIST:
      return [...action.value];  
    default: return state;
  }
}

export default carlist;
