import { SET_USERLISTBYPLUGIN } from '../actions';

const groupuserlist = (state = [], action) => {
  switch (action.type) {
	case SET_USERLISTBYPLUGIN:
      return [...action.value];  
    default: return state;
  }
}

export default groupuserlist;
