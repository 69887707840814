import { SET_PRIORITYUPDATE } from '../actions';

const priorityupdate = (state = [], action) => {
  switch (action.type) {
	case SET_PRIORITYUPDATE:
      return [...action.value];  
    default: return state;
  }
}

export default priorityupdate;
