import { SET_DELETECOMMENT } from '../actions';

const commentdelete = (state = [], action) => {
  switch (action.type) {
	case SET_DELETECOMMENT:
      return [...action.value];  
    default: return state;
  }
}

export default commentdelete;
