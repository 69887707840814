import { SET_ASSIGNDRIVER } from '../actions';

const driverassigned = (state = [], action) => {
  switch (action.type) {
	case SET_ASSIGNDRIVER:
      return [...action.value];  
    default: return state;
  }
}

export default driverassigned;
