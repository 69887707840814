import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';

import {GET_PROFILEDETAILS, GET_UPDATEPROFILE}  from '../../actions'; 
import { PageTitle } from "../Helpers/SettingHelper";

class Adminprofile extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			admin_id:'',
			admin_username:'',
			admin_fname:'',
			admin_lname:'',
			admin_email_address:'',
			statusmessage:'',
			Loading:false
		};
		this.props.getProfileDetails(localStorage.getItem("admin_id"));
		this.handleInputChange = this.handleInputChange.bind(this);
    }
	
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Profile');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
		}
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
			
				var postObject = {
					admin_id: formPayload.admin_id,
					admin_username:formPayload.admin_username,
					admin_fname:formPayload.admin_fname,
					admin_lname:formPayload.admin_lname,
					admin_email_address:formPayload.admin_email_address,
					
				};
				this.props.getUpdateProfile(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {admin_username,admin_fname,admin_lname,admin_email_address} = this.state;
		let errors = 0;
      	
	
		if (!admin_username) {
			errors++;
			$('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_username){
			$('.erroradmin_username').html('');
		}

		if (!admin_fname) {
			errors++;
			$('.erroradmin_fname').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_fname){
			$('.erroradmin_fname').html('');
		}

		if (!admin_lname) {
			errors++;
			$('.erroradmin_lname').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_lname){
			$('.erroradmin_lname').html('');
		}

		if (!admin_email_address) {
			errors++;
			$('.erroradmin_email_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_email_address){
			$('.erroradmin_email_address').html('');
		}


		if(errors>0) {
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	if(Props.profile !== this.props.profile){
    		if(Object.keys(Props.profile).length > 0){
    			const formpayload = Props.profile[0].results;
    			this.setState({admin_id:formpayload.admin_id});
    			this.setState({admin_lname:formpayload.admin_lname});
    			this.setState({admin_fname:formpayload.admin_fname});
    			this.setState({admin_username:formpayload.admin_username});
    			this.setState({admin_email_address:formpayload.admin_email_address});
    			const adminname = formpayload.admin_fname;
    			localStorage.setItem("admin_name",adminname);
    		}
    	}

   	if(Props.updateprofile !== this.props.updateprofile){
    		if(Object.keys(Props.updateprofile).length > 0){
    					this.setState({ Loading: false });

    			const formpayload = Props.updateprofile[0].results;
    			this.setState({admin_id:formpayload.admin_id});
    			this.setState({admin_username:formpayload.admin_username});
    			this.setState({admin_fname:formpayload.admin_fname});
    			this.setState({admin_lname:formpayload.admin_lname});
    			this.setState({admin_email_address:formpayload.admin_email_address});
    				const adminname = formpayload.admin_fname;
    			localStorage.setItem("admin_name",adminname);
				$('.success_message').html('<div class="status_sucess"><h3>'+Props.updateprofile[0].message+'</h3></div>');
          	setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}
    	}
    }
	
  render() {
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar />		

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<div className="form-wrapper profile-form">
				<div className="success_message"></div>
			<div className="title">
				<h4>Profile</h4>
			</div>
			 <form className="profile" id="profile" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">					
					<label>First Name:</label>
						<input type="text" name="admin_fname" onChange={this.handleInputChange} className="form-control" value={this.state.admin_fname} />
					<div className="erroradmin_fname"></div>
				</div>	
				<div className="form-group">
					<label>Username:</label>
				    <input type="text" name="admin_username" onChange={this.handleInputChange} className="form-control" value={this.state.admin_username} />
					<div className="erroradmin_username"></div>
				</div>
							
			</div>
			<div className="form-right">
				<div className="form-group">
					<label>Last Name:</label>
						<input type="text" name="admin_lname" onChange={this.handleInputChange} className="form-control" value={this.state.admin_lname} />
					<div className="erroradmin_lname"></div>
				</div>
				<div className="form-group">					
					<label>Email:</label>
						<input type="text" name="admin_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.admin_email_address} />
					<div className="erroradmin_email_address"></div>
				</div>
			</div>	
			</div>	

			<div className="btn-group export">	
				 {/* <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="button" onClick={this.handleFormSubmit.bind(this)} disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                    </button>*/}

				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
				<span className="load-data"></span> 
				}Submit
				</button>
			</div>
			</form>
			</div>
			
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	profile : state.profile,
	updateprofile: state.updateprofile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getProfileDetails: (adminId) => {
        dispatch({ type: GET_PROFILEDETAILS, adminId });
     },
     getUpdateProfile: (formPayload) => {
      dispatch({ type: GET_UPDATEPROFILE, formPayload });
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Adminprofile));