/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDHELPER, SET_ADDHELPER, GET_HELPERDETAIL ,SET_HELPERDETAIL,GET_HELPERLIST,SET_HELPERLIST,GET_EDITHELPER,SET_EDITHELPER,GET_DELETEHELPER, SET_DELETEHELPER } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*list*/
export const watchGetHelperList = function* () {
  yield takeEvery(GET_HELPERLIST, workerGetHelperList);
}

function* workerGetHelperList({ formVal }) {
  try {
      
 const result = yield call(getHelperList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_HELPERLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getHelperList(formVal) {
   return Axios.post(apiUrl+'helper/list', formVal);
} 



/*save the Helper*/

export const watchGetAddHelper = function* () {
  yield takeEvery(GET_ADDHELPER, workerGetAddHelper);
}

function* workerGetAddHelper({ formPayload }) {
  try {
      
 const result = yield call(getAddHelper, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDHELPER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddHelper(formPayload) {
 //console.log('kskdksdsd',formPayload);
   return Axios.post(apiUrl+'helper/add', formPayload);
} 


/*edit the Helper*/

export const watchGetEditHelper = function* () {
  yield takeEvery(GET_EDITHELPER, workerGetEditHelper);
}

function* workerGetEditHelper({ formPayload }) {
  try {
      
 const result = yield call(getEditHelper, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITHELPER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditHelper(formPayload) {
   return Axios.post(apiUrl+'helper/edit', formPayload);
}


/*delete the Helper*/

export const watchGetDeleteHelper = function* () {
  yield takeEvery(GET_DELETEHELPER, workerGetDeleteHelper);
}

function* workerGetDeleteHelper({ formPayload }) {
  try {
      
 const result = yield call(getDeleteHelper, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEHELPER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteHelper(formPayload) {
  return Axios.post(apiUrl+'helper/delete', formPayload);
} 



/*get Helper detail*/

export const watchGetHelperDetail = function* () {
  yield takeEvery(GET_HELPERDETAIL, workerGetHelperDetail);
}

function* workerGetHelperDetail({ helperId }) {
  var url = apiUrl+'helper/helperdetail?id='+helperId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_HELPERDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  
