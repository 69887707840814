import { all } from "redux-saga/effects";
import { watchGetLoginData } from "./login";
import { watchGetSettingData, watchGetUpdateSetting }from "./setting";
import { watchGetChangePassword }from "./changepassword";
import { watchGetForgetPassword } from "./forgetpassword";
import { watchGetProfileDetails, watchGetUpdateProfile } from './profile';
import { watchGetAddVehicle, watchGetVehicleList, watchGetEditVehicle, watchGetDeleteVehicle, watchGetVehicleDetail } from './vehicle';
import {  watchGetCompanyList,watchGetAddCompany, watchGetEditCompany  } from './company';
import { watchGetVehicleType, watchGetAddCar, watchGetCarList, watchGetEditCar, watchGetDeleteCar, watchGetPriorityUpdate, watchGetCarDetail } from './car';
import { watchGetBookingHistory,watchGetOngoingRide, watchGetCompletedRide, watchGetIncompleteRide, watchGetViewOngoingride,watchGetDriverDropList,watchGetAssignDriver } from './booking';
import {watchGetUserList, watchActivateUser, watchExportUser,watchGetEditUser, watchGetUserDetailList,watchGetAddUser,watchGetImportUser} from './user';
import { watchGetFontList, watchGetFontName, watchGetAddFont, watchGetDeleteFont } from './font';
import { watchGetDriverList,watchActivateDriver, watchExportDriver,watchGetAddDriver, watchGetEditDriver, watchGetDriverDetail,watchGetDeleteDriver, watchGetLocatedDrivers } from './driver';
import {  watchGetEventList,watchGetAddEvent, watchGetEditEvent, watchGetDeleteEvent , watchGetEventDetail } from './event';
import {  watchGetRouteList,watchGetAddRoute, watchGetEditRoute, watchGetDeleteRoute , watchGetRouteDetail } from './route';
import { watchGetTemplateList, watchGetTemplateDetail, watchGetTemplateAdd, watchGetTemplateDelete, watchGetTemplateEdit } from './template';
import {  watchGetHelperList,watchGetAddHelper, watchGetEditHelper, watchGetDeleteHelper , watchGetHelperDetail } from './helper';
import {  watchGetGroupList,watchGetAddGroup, watchGetEditGroup, watchGetDeleteGroup , watchGetGroupDetail, watchGetUserListByPlugin } from './group';

import {  watchGetCommentList,watchGetAddComment, watchGetEditComment, watchGetDeleteComment , watchGetCommentDetail } from './comment';
import { watchGetDomainList } from './common';

export default function*() {
  yield all([
       watchGetLoginData(),
       watchGetSettingData(),
       watchGetUpdateSetting(),
       watchGetChangePassword(),
       watchGetForgetPassword(),
       watchGetProfileDetails(),
       watchGetUpdateProfile(),
       watchGetAddVehicle(),
       watchGetVehicleList(),
       watchGetCompanyList(),
	     watchGetAddCompany(),
       watchGetEditVehicle(),
       watchGetEditCompany(),
       watchGetDeleteVehicle(),
       watchGetVehicleType(),
       watchGetDriverDropList(),
       watchGetAssignDriver(),
       watchGetAddCar(),
       watchGetCarList(),
       watchGetEditCar(),
       watchGetDeleteCar(),
       watchGetPriorityUpdate(),
       watchGetFontList(),
       watchGetFontName(),
       watchGetAddFont(),
  	   watchGetUserList(),
  	   watchActivateUser(),
  	   watchExportUser(),
       watchGetDeleteFont(),
       watchGetEditUser(),
       watchGetDriverList(),
       watchActivateDriver(),
       watchExportDriver(),
       watchGetEditDriver(),
       watchGetOngoingRide(),
       watchGetBookingHistory(),
       watchGetViewOngoingride(),
       watchGetCompletedRide(),
       watchGetIncompleteRide(),
       watchGetUserDetailList(),
       watchGetDriverDetail(),
       watchGetVehicleDetail(),
       watchGetCarDetail(),
       watchGetAddUser(),
	   watchGetImportUser(),
	   watchGetDeleteDriver(),
       watchGetLocatedDrivers(),
       watchGetAddDriver(),
       watchGetAddEvent(),
       watchGetEventList(),
       watchGetEditEvent(),
       watchGetDeleteEvent(),
       watchGetEventDetail(),
       watchGetAddRoute(),
       watchGetRouteList(),
       watchGetEditRoute(),
       watchGetDeleteRoute(),
       watchGetRouteDetail(),
       watchGetTemplateList(),
       watchGetTemplateDetail(),
       watchGetTemplateAdd(),
       watchGetTemplateDelete(),
       watchGetTemplateEdit(),
       watchGetGroupList(),
       watchGetAddHelper(),
       watchGetHelperList(),
       watchGetEditHelper(),
       watchGetDeleteHelper(),
       watchGetHelperDetail(),
       watchGetAddGroup(),
       watchGetGroupList(),
       watchGetEditGroup(),
       watchGetDeleteGroup(),
       watchGetGroupDetail(),
       watchGetUserListByPlugin(),
       watchGetCommentList(),
       watchGetAddComment(),
       watchGetEditComment(), 
       watchGetDeleteComment(), 
       watchGetCommentDetail(),
       watchGetDomainList()
  ]);
}
