/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDGROUP, SET_ADDGROUP, GET_GROUPDETAIL ,SET_GROUPDETAIL,GET_GROUPLIST,SET_GROUPLIST,GET_EDITGROUP,SET_EDITGROUP,GET_DELETEGROUP, SET_DELETEGROUP, GET_USERLISTBYPLUGIN, SET_USERLISTBYPLUGIN } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*list*/
export const watchGetGroupList = function* () {
  yield takeEvery(GET_GROUPLIST, workerGetGroupList);
}

function* workerGetGroupList({ formVal }) {
  try {
      
 const result = yield call(getGroupList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_GROUPLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getGroupList(formVal) {
   return Axios.post(apiUrl+'group/list', formVal);
} 



/*save the Group*/

export const watchGetAddGroup = function* () {
  yield takeEvery(GET_ADDGROUP, workerGetAddGroup);
}

function* workerGetAddGroup({ formPayload }) {
  try {
      
 const result = yield call(getAddGroup, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDGROUP, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddGroup(formPayload) {
 //console.log('kskdksdsd',formPayload);
   return Axios.post(apiUrl+'group/add', formPayload);
} 


/*edit the Group*/

export const watchGetEditGroup = function* () {
  yield takeEvery(GET_EDITGROUP, workerGetEditGroup);
}

function* workerGetEditGroup({ formPayload }) {
  try {
      
 const result = yield call(getEditGroup, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITGROUP, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditGroup(formPayload) {
   return Axios.post(apiUrl+'group/edit', formPayload);
}


/*delete the Group*/

export const watchGetDeleteGroup = function* () {
  yield takeEvery(GET_DELETEGROUP, workerGetDeleteGroup);
}

function* workerGetDeleteGroup({ formPayload }) {
  try {
      
 const result = yield call(getDeleteGroup, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEGROUP, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteGroup(formPayload) {
  return Axios.post(apiUrl+'group/delete', formPayload);
} 



/*get Group detail*/

export const watchGetGroupDetail = function* () {
  yield takeEvery(GET_GROUPDETAIL, workerGetGroupDetail);
}

function* workerGetGroupDetail({ groupId, admin_id }) {
  var url = apiUrl+'group/groupdetail?id='+groupId+'&admin_id='+admin_id;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_GROUPDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} 




/*get Group user detail by domain*/

export const watchGetUserListByPlugin = function* () {
  yield takeEvery(GET_USERLISTBYPLUGIN, workerGetUserListByPlugin);
}

function* workerGetUserListByPlugin({ admin_id }) {
  var url = apiUrl+'group/userdetail?admin_id='+admin_id;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_USERLISTBYPLUGIN, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}   
