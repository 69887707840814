import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

import Geocode from "react-geocode";
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";


import {GET_EDITHELPER,GET_HELPERDETAIL,GET_HELPERLIST}  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		
		super(props);	
	    const helperId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			helper_username:'',			
			helper_lname:'',
			helper_fname:'',
			helper_contact:'',
			helper_email_address:'',
			helper_status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			helper_id: helperId,
			Loading:false,
			password:'',
			changepassword:'',
			disablepasssword:true,
			admin_id: localStorage.getItem('admin_id')

		};
		this.props.getHelperDetail(helperId);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
	
    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		if(name == 'password'){
			this.setState({disablepasssword: false})
		}
		
	}

	componentDidMount() {
		document.title = PageTitle('Helper Edit');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
			}
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					helper_id : formPayload.helper_id,
					helper_username:formPayload.helper_username,			
					helper_lname:formPayload.helper_lname,
					helper_fname:formPayload.helper_fname,
					helper_contact:formPayload.helper_contact,
					currentpswd         : formPayload.password,
					changepassword      : formPayload.changepassword, 
					helper_email_address:formPayload.helper_email_address,
					helper_status:status,
				};
				//console.log(postObject);
				this.props.getEditHelper(qs.stringify(postObject));
			}
	}


	
	handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			 const config = {
				headers: {
				  'Content-Type': 'multipart/form-data'
				}
			  };

			const formPayload = this.state;
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}
			
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				helper_id : formPayload.helper_id,
				helper_username:formPayload.helper_username,			
				helper_lname:formPayload.helper_lname,
				helper_fname:formPayload.helper_fname,
				helper_contact:formPayload.helper_contact,
				helper_email_address:formPayload.helper_email_address,
				currentpswd         : formPayload.password,
				changepassword      : formPayload.changepassword, 
				helper_status:status,
			};
			let formData = new FormData();
			for(let k in postObject) {
				formData.append(k, postObject[k]);
			}
				       

			this.props.getEditHelper(formData,config);
		
		}
}


	validateForm() {
		
		const {helper_username,helper_lname, password, changepassword,helper_fname,helper_contact,helper_email_address,helper_status} = this.state;
		let errors = {};
      	let formIsValid = true;
		  if (!helper_username) {
			formIsValid = false;
			$('.errorhelper_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_username){
			$('.errorhelper_username').html('');
		}
		
		if (!helper_lname) {
			formIsValid = false;
			$('.errorhelper_lname').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_lname){
			$('.errorhelper_lname').html('');
		}		
		

		if (!helper_fname) {
			formIsValid = false;
			$('.errorhelper_fname').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_fname){
			$('.errorhelper_fname').html('');
		}

		if (!helper_contact) {
			formIsValid = false;
			$('.errorhelper_contact').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_contact){
			$('.errorhelper_contact').html('');
		}

		if (!helper_email_address) {
			formIsValid = false;
			$('.errorhelper_email_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_email_address){
			$('.errorhelper_email_address').html('');
		}

		if(password){
			if(!changepassword){
				errors++;
			   $('.errorchangepaswd').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorchangepaswd').html('');
			}
		}else{
			$('.errorchangepaswd').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.helperdetail !== this.props.helperdetail){
    		if(Object.keys(Props.helperdetail).length > 0){
				this.setState({Loading:false});
					if(Props.helperdetail[0].status === "success"){

						const formdata = Props.helperdetail[0].helperlist;
						if(formdata.helper_status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						
						this.setState({helper_username:formdata.helper_username});
						this.setState({helper_lname: formdata.helper_lname});
						this.setState({helper_fname: formdata.helper_fname});
						this.setState({helper_contact: formdata.helper_contact});
						this.setState({helper_email_address: formdata.helper_email_address});

						
						
					}
    		}
    	}
    	if(Props.helperedit !== this.props.helperedit){
    		if(Object.keys(Props.helperedit).length > 0){
				if(Props.helperedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.helperedit[0].helperlist;
				if(formpayload.helper_status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({helper_username:formpayload.helper_username});
				this.setState({helper_lname: formpayload.helper_lname});
				this.setState({helper_fname: formpayload.helper_fname});
				this.setState({helper_contact: formpayload.helper_contact});
				this.setState({helper_email_address: formpayload.helper_email_address});

			
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.helperedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.helperedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	
  render() {
  	  const {selectedOption} = this.state;
		
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="helper" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>First Name</label>
				    <input type="text" name="helper_fname" onChange={this.handleInputChange} className="form-control" value={this.state.helper_fname} />
					<div className="errorhelper_fname"></div>
				</div>

				<div className="form-group">
					<label>Last Name:</label>
				    <input  type="text" name="helper_lname" onChange={this.handleInputChange} className="form-control" value={this.state.helper_lname} />
					<div className="errorhelper_lname"></div>
				</div>


				<div className="form-group">
					<label>Current password:</label>
				    <input type="password" name="password" onChange={this.handleInputChange} className="form-control" value={this.state.password} />
					<div className="errorpassword"></div>
				</div>
				<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.changepassword} disabled={this.state.disablepasssword}/>
					<div className="errorchangepaswd"></div>
				</div>


				

			</div>
			<div className="form-right">	
			
			<div className="form-group">
					<label>User Name:</label>
				    <input type="text" name="helper_username" onChange={this.handleInputChange} className="form-control" value={this.state.helper_username} />
					<div className="errorhelper_username"></div>
				</div>	
			
				<div className="form-group">
					<label>Helper Email:</label>
				    <input type="text" name="helper_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.helper_email_address} />
					<div className="errorhelper_email_address"></div>
				</div>

				<div className="form-group">
					<label>Helper Phone Number:</label>
						<input type="text" name="helper_contact" onChange={this.handleInputChange} className="form-control" value={this.state.helper_contact} />
					<div className="errorhelper_contact"></div>
				</div>

				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>


				
			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	helperdetail  : state.helperdetail,
    helperedit: state.helperedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
	getHelperDetail: (helperId) =>{
		dispatch({ type: GET_HELPERDETAIL,helperId });
	 },
     getEditHelper: (formPayload) =>{
       dispatch({ type: GET_EDITHELPER, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));