/* eslint-disable */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import $ from 'jquery';
import {  GET_FORGETPASSWORD} from '../../actions';

class Forgetpassword extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            admin_email: '',       
            errors: {}, 
            Loading:false,
        };
    }

    componentWillReceiveProps(Props) {

        if(Object.keys(Props.forgetpassword).length > 0){
            this.setState({Loading:false});
            if(Props.forgetpassword !== this.props.forgetpassword){
                if(Props.forgetpassword[0].status === "success"){
                  
                }else{

                
                }
            }
        }
    }


    handleforgetpassFormSubmit = () => {
        if(this.validateForm()){
            this.setState({Loading:true});
            const formPayload = this.state;
                var qs = require('qs');
                var postObject = {
                "admin_email": formPayload.admin_email
                };
                $(".forget_submit").addClass('loading loading_data');
            this.props.getForgetPassword(qs.stringify(postObject));
        }
    }

    validateForm() {
        $('.errorvalid').removeClass('errorMsg');
        const {admin_email} = this.state;        
        let errors = {};
        let formIsValid = true;
        if (!admin_email) {
            formIsValid = false;
            $('.erroremailid').html('<span class="errorspan">This Email Address not found in our database</span>');
        }else if (user_mobile) {
            $('.erroremailid').html('');
        }
        
        this.setState({
            errors: errors
        });
        $(".forget_submit").removeClass('loading loading_data');
        return formIsValid;
    }

render() {
    return (
            <div className="login-wrapper">
                <div className="account-wrapper">
                    <div className="account-title-warpper">
                        <h2 className="title"><span>Forgot Password</span></h2>
                    </div>
                    <div className="login-form">
                        <form>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" className="form-control" name="admin_email" id="admin_email" value={this.state.admin_email} onChange={this.handleInputChange} />
                            <div className="erroremailid"></div>
                        </div>
                            <div className="account-submit">
                                <button className="btn btn_orange btn_minwid forget_submit animate-btn2" onClick={this.handleforgetpassFormSubmit.bind(this)} type="button" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                <span className="load-data"></span> 
                                } Submit</button>	
                            </div>
                            <div className="account-backreset-wrap">
                            <a href="/"><i className="fa fa-angle-left" aria-hidden="true"></i> Back to Login</a>   
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
    );
}
}

const mapStateTopProps = (state) => {
  return {
    forgetpassword  : state.forgetpassword,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getForgetPassword: (formPayload) => {
        dispatch({ type: GET_FORGETPASSWORD, formPayload});
    }
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Forgetpassword));
