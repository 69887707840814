import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from "react-dom";

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import { Provider } from "react-redux";
import { getStore } from "./store";

//import * as serviceWorker from './serviceWorker';

import './common/css/font-awesome.min.css';

import Home from './components/Home/Home';
import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import List from "./components/Vehicle/List";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";
import Add from "./components/Vehicle/Add";
import Edit from "./components/Vehicle/Edit";
import CarList from "./components/Car/List";
import CarAdd from "./components/Car/Add";
import CarEdit from "./components/Car/Edit";
import DriverAssign from "./components/Bookinghistory/Assign";

import Bookinglist from "./components/Bookinghistory/List";

import UserList from "./components/User/List";
import UserEdit from "./components/User/Edit";
import UserAdd from "./components/User/Add";
import UserImport from "./components/User/ImportUser";

import DriverList from "./components/Driveruser/List";
import DriverEdit from "./components/Driveruser/Edit";
import DriverAdd from "./components/Driveruser/Add";

import Locatedrivers from "./components/Driveruser/LocateDrivers";

import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit";


import Ongoingride from "./components/Ongoingride/List";
import OngoingrideViewDriver from "./components/Ongoingride/View";
import Completedride from "./components/Completedride/List";
import Incompleteride from './components/Incompleteride/List';


import Event from "./components/Event/List";
import EventAdd from "./components/Event/Add"
import EventEdit from "./components/Event/Edit";
import EventView from "./components/Event/View";

import Rroute from "./components/Route/List";
import RouteAdd from "./components/Route/Add"
import RouteEdit from "./components/Route/Edit";


import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';



import Helper from "./components/Helper/List";
import HelperAdd from "./components/Helper/Add"
import HelperEdit from "./components/Helper/Edit";

import Group from "./components/Group/List";
import GroupAdd from "./components/Group/Add"
import GroupEdit from "./components/Group/Edit";


import Comments from "./components/Comments/List";
import CommentAdd from "./components/Comments/Add"
import CommentEdit from "./components/Comments/Edit";

import './common/css/custom.css';

/*ReactDOM.render(
		<Router>
			<Switch>
				<Route exact path='/' component={Login}/>
				<Route path="/home" component={Home} />
				
			</Switch>
		</Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();*/

const store = getStore();
render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/profile" component={Adminprofile} />
        <Route path="/logout" component={Logout} />
        <Route path="/setting" component={Setting} />
        <Route path="/changepassword" component={Changepassword} />
        <Route path="/forget_password" component={Forgetpassword} />
        <Route path="/reset_password/:key" component={Resetpassword} />
        <Route path="/vehicle/edit/:ID" component={Edit} />
        <Route path="/vehicle" component={List} />
        <Route path="/vehicle-add" component={Add} />
        <Route path="/company" component={Company} />
        <Route path="/company-add" component={CompanyAdd} />
        <Route path="/company-edit/:ID" component={CompanyEdit} />
        <Route path="/bus/edit/:ID" component={CarEdit} />
        <Route path="/bus/assign_driver/:ID" component={DriverAssign} />
        <Route path="/bus" component={CarList} />
        <Route path="/bus-add" component={CarAdd} />
		    <Route path="/bookinghistory" component={Bookinglist} />
        <Route path="/fonts" component={Fontlist} />
        <Route path="/locatedrivers" component={Locatedrivers} />
        <Route path="/font-add" component={FontAdd} />
        <Route path="/font/edit/:ID" component={FontEdit} />
		    <Route path="/user" component={UserList} />
        <Route path="/edit/:ID" component={UserEdit} />
        <Route path="/user-add" component={UserAdd} />
        <Route path="/user-import" component={UserImport} />
        <Route path="/driveruser" component={DriverList} />
        <Route path="/driver-add" component={DriverAdd} />
        <Route path="/editdriver/:ID" component={DriverEdit} />
        <Route path="/ongoingride" component={Ongoingride} />
		 <Route path="/ongoingview/:ID" component={OngoingrideViewDriver} />
        <Route path="/completedride" component={Completedride} />
        <Route path="/incompleteride" component={Incompleteride} />
        <Route path="/event" component={Event} />
        <Route path="/event-add" component={EventAdd} />
        <Route path="/event-edit/:ID" component={EventEdit} />
        <Route path="/event-view/:ID" component={EventView} />
        <Route path="/route" component={Rroute} />
        <Route path="/route-add" component={RouteAdd} />
        <Route path="/route-edit/:ID" component={RouteEdit} />
        <Route path="/templates" component={TemplateList} />
        <Route path="/template/add" component={TemplateAdd} />
        <Route path="/templates-edit/:ID" component={TemplateEdit} />
        <Route path="/helper" component={Helper} />
        <Route path="/helper-add" component={HelperAdd} />
        <Route path="/helper-edit/:ID" component={HelperEdit} />
        <Route path="/group" component={Group} />
        <Route path="/group-add" component={GroupAdd} />
        <Route path="/group-edit/:ID" component={GroupEdit} />
        <Route path="/Comments" component={Comments} />
        <Route path="/comment-add" component={CommentAdd} />
        <Route path="/comment-edit/:ID" component={CommentEdit} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);