import { SET_DRIVERDETAIL } from '../actions';

const driverdetail = (state = [], action) => {
  switch (action.type) {
	case SET_DRIVERDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export default driverdetail;
