import { SET_DELETEHELPER } from '../actions';

const deletehelper = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEHELPER:
      return [...action.value];  
    default: return state;
  }
}

export default deletehelper;
