import { SET_GROUPLIST } from '../actions';

const grouplist = (state = [], action) => {
  switch (action.type) {
	case SET_GROUPLIST:
      return [...action.value];  
    default: return state;
  }
}

export default grouplist;
