/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_VEHICLETYPE, SET_VEHICLETYPE, GET_ADDCAR, SET_ADDCAR, GET_CARLIST, SET_CARLIST, GET_EDITCAR, SET_EDITCAR, GET_DELETECAR, SET_DELETECAR, GET_PRIORITYUPDATE,SET_PRIORITYUPDATE, GET_CARDETAIL, SET_CARDETAIL} from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*export const watchGetVehicleType = function* () {
  yield takeEvery(GET_VEHICLETYPE, workerGetVehicleType);
}

function* workerGetVehicleType(adminID) {
  console.log(adminID)
  try {
    const uri = apiUrl+'car/dropdownvehiclelist';
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VEHICLETYPE, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} */


export const watchGetVehicleType = function* () {
  yield takeEvery(GET_VEHICLETYPE, workerGetVehicleType);
}

function* workerGetVehicleType({ adminID }) {
  var url = apiUrl+'car/dropdownvehiclelist?adminID='+adminID;
  console.log(url)
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VEHICLETYPE, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}   

/*save the car*/

export const watchGetAddCar = function* () {
  yield takeEvery(GET_ADDCAR, workerGetAddCar);
}

function* workerGetAddCar({ formPayload }) {
  try {
      
 const result = yield call(getAddCar, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDCAR, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddCar(formPayload) {
   return Axios.post(apiUrl+'car/add', formPayload);
} 


/*list*/



export const watchGetCarList = function* () {
  yield takeEvery(GET_CARLIST, workerGetCarList);
}

function* workerGetCarList({ formVal }) {
  try {
      
 const result = yield call(getCarList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_CARLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getCarList(formVal) {
   return Axios.post(apiUrl+'car/list', formVal);
} 


/*get Car detail*/

export const watchGetCarDetail = function* () {
  yield takeEvery(GET_CARDETAIL, workerGetCarDetail);
}

function* workerGetCarDetail({ carId }) {
  var url = apiUrl+'car/cardetail?id='+carId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CARDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  


/*edit the vehicle*/

export const watchGetEditCar = function* () {
  yield takeEvery(GET_EDITCAR, workerGetEditCar);
}

function* workerGetEditCar({ formPayload }) {
  try {
      
 const result = yield call(getEditCar, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITCAR, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditCar(formPayload) {
   return Axios.post(apiUrl+'car/edit', formPayload);
} 



/*delete the car*/

export const watchGetDeleteCar = function* () {
  yield takeEvery(GET_DELETECAR, workerGetDeleteCar);
}

function* workerGetDeleteCar({ formPayload }) {
  try {
      
 const result = yield call(getDeleteCar, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETECAR, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteCar(formPayload) {
   return Axios.post(apiUrl+'car/delete', formPayload);
} 

/*update the priority field update value*/

export const watchGetPriorityUpdate = function* () {
  yield takeEvery(GET_PRIORITYUPDATE, workerGetPriorityUpdate);
}

function* workerGetPriorityUpdate({ formPayload }) {
  try {
      
 const result = yield call(getPriorityUpdate, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_PRIORITYUPDATE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getPriorityUpdate(formPayload) {
  console.log(formPayload)
   return Axios.post(apiUrl+'car/updatepriority', formPayload);
} 