import { SET_HELPERLIST } from '../actions';

const helperlist = (state = [], action) => {
  switch (action.type) {
	case SET_HELPERLIST:
      return [...action.value];  
    default: return state;
  }
}

export default helperlist;
