import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';

import {GET_EDITCAR,GET_CARDETAIL,GET_VEHICLETYPE  }  from '../../actions'; 

class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
		const carId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			car_name:'',
			car_id:'',
			car_price:'',
			car_colour:'',
			car_no_plate:'',
			car_model:'',
			ride_information: '',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			user_image: [],
			vehicletype:[],
			user_image_name: [],
			user_image_preview: [],
			isHidden: false,
			selectedVehicle:'',
			vehiclevalue:'',
			carId: carId,
			Loading:false,
			fileName: [],
			thumbDefault: '',
			thumbtypeimg: [],	
			priority:'',
			removedimage:'',
			additional_charges:'',
			extra_time_limit:'',
			extra_time_limit_charge:'',
			enableweight: false,
			truck_weight:'',
			cancellation_fee:'',
			cancellation_hour:'',
			admin_id: localStorage.getItem('admin_id')
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onChangeHandler = this.onChangeHandler.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.removeImage = this.removeImage.bind(this);
	   this.handlesizeChange = this.handlesizeChange.bind(this);
	   this.props.getVehicleType(this.state.admin_id);
	   this.props.getCarDetail(carId);
	this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    }
	

	
	
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('Bus Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

   handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value });
      if(selectedVehicle.value === ''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }
      let vehicletruck = selectedVehicle.label;
      let lowercasetext = vehicletruck.toLowerCase();
      if(lowercasetext == 'truck'){
      	this.setState({enableweight: true})
      }else{
      	this.setState({enableweight: false, truck_weight:0})
      }
   }

  onChangeHandler1=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
		
		console.log("commet1",reader.result);
		
	this.setState(prevState => ({ 
		user_image_name: [...prevState.user_image_name, file.name]		
    }))
	this.setState(prevState => ({ 
		user_image_preview: [...prevState.user_image_preview, reader.result]	
    }))
	this.setState(prevState => ({ 
		user_image: [...prevState.user_image,file]	
    }))
	// let user_image_name = [...this.state.user_image_name];
	
     /* this.setState({
        user_image_name: file.name,
        user_image_preview: reader.result,
        user_image: file
      });*/
	 
		
    };
	
	//console.log(this.state.user_image_preview);
	//console.log(this.state.user_image);
    reader.readAsDataURL(file);
  }
  
  
  onChangeHandler = event =>{
    //Check File API support
   if (window.File && window.FileList && window.FileReader) {
		var callfunc = this;
        var files = event.target.files; //FileList object
      
		var arrFilesCount = [];
                
        for (var i = 0; i < files.length; i++) {
			console.log(i);
            arrFilesCount.push(i);
            var file = files[i];
            //Only pics
            if (!file.type.match('image')) continue;

            var picReader = new FileReader();
				picReader.addEventListener("load", function (event) {
                var picFile = event.target;

               
				callfunc.setState(prevState => ({ 
					user_image_name: [...prevState.user_image_name, file.name]		
				}))
				callfunc.setState(prevState => ({ 
					user_image_preview: [...prevState.user_image_preview, picFile.result]	
				}))
				callfunc.setState(prevState => ({ 
					user_image: [...prevState.user_image,file]	
				}))
			
				
				/*current_i = arrFilesCount.shift();
				prev_i = current_i - 1;
				next_i = current_i + 1;

               
                output.innerHTML = output.innerHTML + '<li id="slide-' + current_i + '" class="slide">' + "<img src='" + picFile.result + "'" + "title=''/>" + '<nav>' + '<a class="prev" href="#slide-' + prev_i + '">&larr;</a>' + '<a class="next" href="#slide-' + next_i + '">&rarr;</a>' + '</nav>' + '<li>';  
                */
            });
            //Read the image
            picReader.readAsDataURL(file);
        }
		console.log(this.state.user_image_preview);
		} else {
        console.log("Your browser does not support File API");
    }
   
}


onThumbChanged = (e) => {	
    this.setState({
      thumbDefault: e.currentTarget.value
      });
	  
  }
  
uploadMultipleFiles = (e) =>{
	this.fileObj = [];

        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
		this.setState({ user_image: this.imageArray })		
    }

 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var vehicletype;
				if(formPayload.selectedVehicle !== ''){
				vehicletype =formPayload.selectedVehicle.value;
				}else{
				vehicletype =formPayload.vehiclevalue;
				}
				var imgSize = '';
				if(formPayload.selectedsizevalue === ''){
				imgSize =formPayload.selectedsizeOption.value;
				}else{
				imgSize = formPayload.selectedsizevalue;
				}
				if(formPayload.removedimage !==''){
					var removedId = formPayload.removedimage;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					car_id : formPayload.car_id,
					car_name: formPayload.car_name,
					car_price:formPayload.car_price,
					car_colour:formPayload.car_colour,
					car_no_plate:formPayload.car_no_plate,
					car_model: formPayload.car_model,
					ride_information: formPayload.ride_information,
					priority        : formPayload.priority,
					vehicle_type    : vehicletype,
					cancellation_fee: formPayload.cancellation_fee,
					car_status      :status,
					image_size      :imgSize,
					thumbDefault :  formPayload.thumbDefault,
					additional_charges: formPayload.additional_charges,
					extra_time_limit: formPayload.extra_time_limit,
					extra_time_limit_charge: formPayload.extra_time_limit_charge,
					removedId    :   removedId,
					truck_weight : formPayload.truck_weight,
					cancellation_hour : formPayload.cancellation_hour
				};
				let formData = new FormData();
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				var filesdata = this.state.user_image;
				for (var i in filesdata) {
				 formData.append('car_image[]',filesdata[i])
				}		       

			    this.props.getEditCar(formData,config);
				//this.props.getAddCar(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {car_name,car_colour,car_no_plate,car_price,car_model,ride_information,selectedVehicle,user_image_preview, additional_charges, extra_time_limit, extra_time_limit_charge, cancellation_fee, cancellation_hour} = this.state;
      	//let formIsValid = true;
		let errors = 0;
		if (!car_name) {
			errors++;
			$('.errorcar_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_name){
			$('.errorcar_name').html('');
		}

		/*if (!car_colour) {
			errors++;
			$('.errorcar_colour').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_colour){
			$('.errorcar_colour').html('');
		}*/

		// if (!car_price) {
		// 	errors++;
		// 	$('.errorcar_price').html('<span class="errorspan">Please fill the field</span>');
		// }else if(car_price){
		// 	$('.errorcar_price').html('');
		// }
	/*	if (!car_no_plate) {
			errors++;
			$('.errorcar_no_plate').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_no_plate){
			$('.errorcar_no_plate').html('');
		}*/
		if (!car_model) {
			errors++;
			$('.errorcar_model').html('<span class="errorspan">Please fill the field</span>');
		}else if(car_model){
			$('.errorcar_model').html('');
		}
		if (!ride_information) {
			errors++;
			$('.errorride_information').html('<span class="errorspan">Please fill the field</span>');
		}else if(ride_information){
			$('.errorride_information').html('');
		}

		if (selectedVehicle.value === undefined || selectedVehicle.value === '') {
		$('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorvehicletype').html('');
		}

		if (user_image_preview === undefined || user_image_preview === '') {
		$('.errorcarimage').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorcarimage').html('');
		}

		// if(!additional_charges){
		// 	errors++;
		// 	$('.erroradditional_charges').html('<span class="errorspan">Please fill the field</span>');
		// }else{
		// 	$('.erroradditional_charges').html('');
		// }
		
		// if(!extra_time_limit){
		// 	errors++;
		// 	$('.errorextra_time_limit').html('<span class="errorspan">Please fill the field</span>');
		// }else{
		// 	$('.errorextra_time_limit').html('');
		// }
		
		// if(!extra_time_limit_charge){
		// 	errors++;
		// 	$('.errorextra_time_limit_charge').html('<span class="errorspan">Please fill the field</span>');
		// }else{
		// 	$('.errorextra_time_limit_charge').html('');
		// }		
		

		// if(!cancellation_fee){
		// 	errors++;
		// 	$('.errorcar_cacellation').html('<span class="errorspan">Please fill the field</span>');
		// }else{
		// 	$('.errorcar_cacellation').html('');
		// }

		// if(!cancellation_hour){
		// 	errors++;
		// 	$('.errorcar_cacellationhour').html('<span class="errorspan">Please fill the field</span>');
		// }else{
		// 	$('.errorcar_cacellationhour').html('');
		// }

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	   if(Props.cardetail !== this.props.cardetail){
    		if(Object.keys(Props.cardetail).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.cardetail[0].status === "success"){

						const formdata = Props.cardetail[0].carlist;
						
						if(formdata.car_status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({car_name:formdata.car_name});
						this.setState({car_colour:formdata.car_colour});
						this.setState({car_no_plate:formdata.car_no_plate});
						this.setState({car_model:formdata.car_model});
						this.setState({car_price:formdata.car_price});
						this.setState({ride_information:formdata.ride_information});
						this.setState({car_id: formdata.car_id});
						this.setState({priority:formdata.priority});
						this.setState({additional_charges : formdata.car_addition_charges})
						this.setState({extra_time_limit : formdata.extra_time_limit})
						this.setState({extra_time_limit_charge : formdata.extra_time_limit_charge})
						this.setState({user_image_preview: formdata.car_image});
						this.setState({truck_weight: formdata.truck_weight})
						this.setState({cancellation_fee: formdata.cancellation_fee})
						this.setState({cancellation_hour: formdata.cancellation_hour})
					   if(Props.cardetail[0].vehicletype!== '' && Props.cardetail[0].vehicletype!== null){
			              this.setselectedvalue(Props.cardetail[0].vehicletype);
			           }

						let vehicletruck = Props.cardetail[0].vehicletype[0].label;
						let lowercasetext = vehicletruck.toLowerCase();
						if(lowercasetext == 'truck'){
						this.setState({enableweight: true})
						}
					  
					   if(Props.cardetail[0].thumbtypeimg!== '' && Props.cardetail[0].thumbtypeimg!== null){
					   	  this.getdefaultposition(Props.cardetail[0].thumbtypeimg);
			              this.setState({ thumbtypeimg : Props.cardetail[0].thumbtypeimg})
			           }
					   
					   
					if(formdata.image_size === '350x350'){
						this.setState({selectedsizeOption:{value: '350x350', label: '350x350'}});
					}else if(formdata.image_size === '250x250'){
						this.setState({selectedsizeOption:{value: '250x250', label: '250x250'}});
					}else{
						this.setState({selectedsizeOption:{value: '520x520', label: '520x520'}});
					}

					if(Props.cardetail[0].vehicletype.length > 0){
					if(Props.cardetail[0].vehicletype[0].value!== '' && Props.cardetail[0].vehicletype.value!== null){
						this.setState({ selectedVehicle : Props.cardetail[0].vehicletype[0]})
						}
					}
					}
    		}
    	}
		
		
			
     if(Props.vehicletype.length > 0){
    	 if(Props.vehicletype[0].vehiclelist!== '' && Props.vehicletype[0].vehiclelist!== null){
              this.setState({ vehicletype : Props.vehicletype[0].vehiclelist})
         }
     }
	 
	


         if(Props.caredit !== this.props.caredit){
    		if(Object.keys(Props.caredit).length > 0){
    					this.setState({ Loading: false });

    			const formdata = Props.caredit[0].carlist;
				if(formdata.car_status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({car_name:formdata.car_name});
				this.setState({car_colour:formdata.car_colour});
				this.setState({car_no_plate:formdata.car_no_plate});
				this.setState({car_model:formdata.car_model});
				this.setState({car_price:formdata.car_price});
				this.setState({priority:formdata.priority});
				this.setState({ride_information:formdata.ride_information});
			    this.setState({car_id: formdata.car_id});
			    this.setState({additional_charges : formdata.car_addition_charges});
			    this.setState({extra_time_limit : formdata.extra_time_limit});
			    this.setState({extra_time_limit_charge : formdata.extra_time_limit_charge});
			    this.setState({cancellation_fee: formdata.cancellation_fee});
				this.setState({cancellation_hour: formdata.cancellation_hour});
				/*if(Props.caredit[0].vehicletype!== '' && Props.caredit[0].vehicletype!== null){
				this.setselectedvalue(Props.caredit[0].vehicletype);

				}*/

				if(Props.caredit[0].vehicletype.length > 0){
					if(Props.caredit[0].vehicletype[0].value!== '' && Props.caredit[0].vehicletype.value!== null){
						this.setState({ selectedVehicle : Props.caredit[0].vehicletype[0]})
						}
					}
					let vehicletruck = Props.caredit[0].vehicletype[0].label;
					let lowercasetext = vehicletruck.toLowerCase();
					if(lowercasetext == 'truck'){
					this.setState({enableweight: true})
					}
					
                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.caredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}
    	}   
      }


  getImages(){
   var imageArray = this.state.thumbtypeimg;
       if(imageArray!== undefined && imageArray!== null){
      if(Object.keys(imageArray).length > 0) {
		  var thumbDefaultArr = this.state.thumbDefault;
         const imageslist = imageArray.map((imageslist, Index) => {
				var indicheckFlag = false;
				if(thumbDefaultArr == Index){
					indicheckFlag = true;
				}
				var classfield = "thumb thumb_"+imageslist.car_id;
              return (
						<li className="thumb" key={Index}>
						<input type="radio" name="sel_img" id="sel_img" value={Index}  onClick={this.onThumbChanged} checked={indicheckFlag} />
						<span><img src={carImageUrl+"/"+imageslist.thumb_img}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImage.bind(this,imageslist.thumb_id,Index)}>X</a>
						</li>
					
                  );
    
       });
      return imageslist;
     }
    } else {
      return '';
    }
  }

  getdefaultposition = (defaultThumb) => {
		const listItems = defaultThumb.map((defaultThumb,index) =>
		this.selectdefaultpos(defaultThumb,index)
		);
		
   }
  selectdefaultpos(value,index){
	  if(value.default_thumb === '1'){
	    this.setState({thumbDefault: index})
	  }
  }
   setselectedvalue(selectsers){
    const vehicleArray = selectsers.map ((vehicleType, index) => ({
     id: index,
     name: vehicleType,
    }));
    // Dynamically create select list
    let vehicles = [];
    vehicleArray.map(item =>
    vehicles.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedVehicle : vehicles})
  }

  removeImage= (Id,Index) =>{
  	var imageArray = this.state.thumbtypeimg;
    const newList = imageArray.filter((item) => item.thumb_id !== Id);
    this.setState({thumbtypeimg: newList})

    var thumbDefault = this.state.thumbDefault;
    if(Index <= thumbDefault){
    	let thum = thumbDefault-1;
    	 if(thum < 0 ){
    	   this.setState({thumbDefault : 0})
   		 }else{
    		this.setState({thumbDefault : thum});
    	}    
    }
    /*set the removed id array*/
     var removeArray = [...this.state.removedimage];

     const index = removeArray.findIndex(district_select => district_select === Id);
     if(index > -1) {
	 removeArray = [...removeArray.slice(0, index), ...removeArray.slice(index + 1)]
	 } else {
	 removeArray.push(Id);
	 }
	 
	 this.setState({removedimage: removeArray});
  }
  removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		
		var thumbDefault = this.state.thumbDefault;
		if(indexvalue <= thumbDefault){
			let thum = thumbDefault-1;
			 if(thum < 0 ){
			   this.setState({thumbDefault : 0})
			 }else{
				this.setState({thumbDefault : thum});
			}    
		}
	}
	
  render() {

	let { user_image_preview} = this.state;	
	let carImg = '';
	let preview = '';
	
	/*if (user_image_preview!== null && user_image_preview!== '') {
	    carImg = carImageUrl + "/" + user_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}*/
	/*====Thumb image from db===*/
	const imageArray = this.state.thumbtypeimg.map ((imagelist, index) => ({
		id: index,
		thumb_id: imagelist.thumb_id,
		imagethumb: imagelist.thumb_img,
	}));
	// Dynamically create select list
	let imageslist = [];
	imageArray.map((item,index) =>
		imageslist.push(<li className="thumb">
		<input type="radio" name="sel_img" id="sel_img"  value={index}  onClick={this.onThumbChanged}  />
		<img src={carImageUrl+"/"+item.imagethumb}  alt="..." />
		
		</li>	
		)
		
	);
	/*=======*/
	/*====Thumb image while choosing from front end===*/
	let indexlength =  imageslist.length;
	const imgThumblist =  this.fileArray.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<input type="radio" name="sel_img" id="sel_img"  value={indexlength+index} onClick={this.onThumbChanged}  />
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				))
				
	/*=======*/

	const vehicleArray = this.state.vehicletype.map ((vehicleType, index) => ({
	id: index,
	name: vehicleType,
	}));
	// Dynamically create select list
	let vehicles = [];
	vehicleArray.map(item =>
	vehicles.push({ label: item.name.vehicle_type, value: item.name.vehicle_id }),
	);
  	const {selectedOption,selectedVehicle} = this.state;
	
	
	
	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="bus" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>Bus Name:</label>
				    <input type="text" name="car_name" onChange={this.handleInputChange} className="form-control" value={this.state.car_name} />
					<div className="errorcar_name"></div>
				</div>
				<div className="form-group vehicles-select">
					<label>Bus Type:</label>
				     <Select 
                         options={vehicles}  
                         value={selectedVehicle?selectedVehicle:{ value: '0', label: 'Select Vehicle Type' }}
                         onChange={this.handleChangeVehicle}
                         placeholder="Select Vehicle Type" />
					<div className="errorvehicletype"></div>
				</div>
               <div className="form-group">
					<label>Bus Image:</label>
				   <div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn">
				    <input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
                </div>
					
				 <div className="form-group">
				 
					 <ul className="carimagelisting">
						{/*imageslist*/}
						{this.getImages()}
						{imgThumblist}
						
					</ul>
				</div>
			    <div className="errorcarimage"></div> 
                </div>
				<div className="form-group">
				</div>
				
				<div className="form-group">					
					<label>Image Size:</label>
						   <Select 
						   value={this.state.selectedsizeOption?this.state.selectedsizeOption:{ value: '350x350', label: '350x350'}}
                            options={lang.common.image_size_option} 
                            onChange={this.handlesizeChange}
                            />
				</div>

			{/*	<div className="form-group">					
					<label>Basic Price:</label>
						<input type="text" name="car_price" onChange={this.handleInputChange} className="form-control" value={this.state.car_price} />
					<div className="errorcar_price"></div>
				</div>
				<div className="form-group">
				  <label>Additional charges per miles: </label>
				  <input type="text" name="additional_charges" onChange={this.handleInputChange} className="form-group" value={this.state.additional_charges} />
				  <div className="erroradditional_charges"></div>
				</div>
				<div className="form-group">
				  <label>Time Limit : </label>
				  <input type="text" name="extra_time_limit" onChange={this.handleInputChange} className="form-group" value={this.state.extra_time_limit} />
				  <div className="errorextra_time_limit"></div>
				</div>
				
				<div className="form-group">
				  <label>Time Limit charges per minute: </label>
				  <input type="text" name="extra_time_limit_charge" onChange={this.handleInputChange} className="form-group" value={this.state.extra_time_limit_charge} />
				  <div className="errorextra_time_limit_charge"></div>
	</div> */}
				
				{this.state.enableweight &&
				<div className="form-group">
				  <label>Weight: </label>
				  <input type="text" name="truck_weight" onChange={this.handleInputChange} className="form-group" value={this.state.truck_weight} autoComplete="off" />
				  <div className="errortruck_weight"></div>
				</div>
				}
				</div>
			<div className="form-right">
				{/*<div className="form-group">
					<label>Car Number:</label>
						<input type="text" name="car_no_plate" onChange={this.handleInputChange} className="form-control" value={this.state.car_no_plate} />
					<div className="errorcar_no_plate"></div>
				</div>*/}
				<div className="form-group">
					<label>Model:</label>
						<input type="text" name="car_model" onChange={this.handleInputChange} className="form-control" value={this.state.car_model} />
					<div className="errorcar_model"></div>
				</div>
				<div className="form-group">
					<label>Ride Information:</label>
						<input type="text" name="ride_information" onChange={this.handleInputChange} className="form-control" value={this.state.ride_information} />
					<div className="errorride_information"></div>
				</div>
				
				<div className="form-group">
				  <label>Priority for listing: </label>
				  <input type="text" name="priority" onChange={this.handleInputChange} className="form-group" value={this.state.priority} />
				</div>
				{/*<div className="form-group">
					<label>Color:</label>
						<input type="text" name="car_colour" onChange={this.handleInputChange} className="form-control" value={this.state.car_colour} />
					<div className="errorcar_colour"></div>
				</div>
				<div className="form-group">
					<label>Cancellation hour:</label>
						<input  id="appt-time" type="time" name="cancellation_hour" onChange={this.handleInputChange} className="form-control" value={this.state.cancellation_hour} autoComplete="off" />
					<div className="errorcar_cacellationhour"></div>
				</div>
				<div className="form-group">
					<label>Cancellation %:</label>
						<input type="text" name="cancellation_fee" onChange={this.handleInputChange} className="form-control" value={this.state.cancellation_fee} autoComplete="off" />
					<div className="errorcar_cacellation"></div>
				</div>*/}
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	vehicletype : state.vehicletype,
  	cardetail     : state.cardetail,
  	caredit     : state.caredit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getVehicleType: (adminID) =>{
       dispatch({ type: GET_VEHICLETYPE, adminID});
    },
     getCarDetail: (carId) =>{
       dispatch({ type: GET_CARDETAIL,carId });
    },
    getEditCar: (formPayload) =>{
       dispatch({ type: GET_EDITCAR, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));