/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDCOMMENT, SET_ADDCOMMENT, GET_COMMENTDETAIL ,SET_COMMENTDETAIL,GET_COMMENTLIST,SET_COMMENTLIST,GET_EDITCOMMENT,SET_EDITCOMMENT,GET_DELETECOMMENT, SET_DELETECOMMENT } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/* Comment list*/
export const watchGetCommentList = function* () {
  yield takeEvery(GET_COMMENTLIST, workerGetCommentList);
}

function* workerGetCommentList({ formVal }) {
  try {
      
 const result = yield call(getCommentList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_COMMENTLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getCommentList(formVal) {
   return Axios.post(apiUrl+'comment/list', formVal);
} 



/*save the Comment*/

export const watchGetAddComment = function* () {
  yield takeEvery(GET_ADDCOMMENT, workerGetAddComment);
}

function* workerGetAddComment({ formPayload }) {
  try {
      
 const result = yield call(getAddComment, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDCOMMENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddComment(formPayload) {
 //console.log('kskdksdsd',formPayload);
   return Axios.post(apiUrl+'comment/add', formPayload);
} 


/*edit the Comment*/

export const watchGetEditComment = function* () {
  yield takeEvery(GET_EDITCOMMENT, workerGetEditComment);
}

function* workerGetEditComment({ formPayload }) {
  try {
      
 const result = yield call(getEditComment, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITCOMMENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditComment(formPayload) {
   return Axios.post(apiUrl+'comment/edit', formPayload);
}


/*delete the Comment*/

export const watchGetDeleteComment = function* () {
  yield takeEvery(GET_DELETECOMMENT, workerGetDeleteComment);
}

function* workerGetDeleteComment({ formPayload }) {
  try {
      
 const result = yield call(getDeleteComment, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETECOMMENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteComment(formPayload) {
  return Axios.post(apiUrl+'comment/delete', formPayload);
} 



/*get Comment detail*/

export const watchGetCommentDetail = function* () {
  yield takeEvery(GET_COMMENTDETAIL, workerGetCommentDetail);
}

function* workerGetCommentDetail({ routeId }) {
  var url = apiUrl+'comment/commentdetail?id='+routeId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_COMMENTDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  
