import { SET_EDITDRIVER } from '../actions';

const driveredit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITDRIVER:
      return [...action.value];  
    default: return state;
  }
}

export default driveredit;
