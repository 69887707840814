import { SET_DELETEEVENT } from '../actions';

const deleteevent = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEEVENT:
      return [...action.value];  
    default: return state;
  }
}

export default deleteevent;
