import { SET_DOMAINLIST } from '../actions';

const domainlist = (state = [], action) => {
  switch (action.type) {
	case SET_DOMAINLIST:
      return [...action.value];  
    default: return state;
  }
}

export default domainlist;
