import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { GET_VIEWONGOINGRIDE,GET_DRIVERDROPDOWNLIST,GET_ASSIGNDRIVER } from '../../actions';
import Select from 'react-select';

class Assign extends Component {
  
  constructor(props)
  {
    super(props); 
    const rideId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
    this.state = {      
      rideId: rideId,
      ongoingrideview:'',
      Loading:false,
	  admin_id: localStorage.getItem('admin_id'),
	  driverdropdownlist:'',
	  selectedDriver:'',
	  driverlist:[],
	  drivervalue:'',
	  driverdistancelist:'',
	  driverdistancelist1:''
    };
  
    var qs = require('qs');
    var postObject = {             
    id   :rideId
    };
    this.props.getViewOngoingride(qs.stringify(postObject));  
	this.props.getDriverDropDownList(this.state.admin_id,this.state.rideId);
  }

   
   componentDidMount() {
     document.title = PageTitle('Ongoing View');
   }

   componentDidUpdate(prevProps,prevState){
      var long = this.state.ongoingrideview.from_long;
      var lat = this.state.ongoingrideview.from_lat;

      
   }
   componentWillReceiveProps(Props){
	   	   
	    if (Object.keys(Props.ongoingrideview).length > 0) {
        if(Props.ongoingrideview[0].status == "success"){
          this.setState({ongoingrideview: Props.ongoingrideview[0].driver_data[0]}) 
        }
      }
	  
	   if (Object.keys(Props.driverdropdownlist).length > 0) {	
	     if(Props.driverdropdownlist[0].status == "success"){
		  if(Object.keys(Props.driverdropdownlist[0].driverdistancelist).length > 0){
			 this.setselecteddistance(Props.driverdropdownlist[0].driverdistancelist)
		  }
		  if(Object.keys(Props.driverdropdownlist[0].driverlist).length > 0){
			this.setState({driverlist: Props.driverdropdownlist[0].driverlist})
		  }
		   
         }
        }
		
		
		if(Props.driverassigned !== this.props.driverassigned){
    		if(Object.keys(Props.driverassigned).length > 0){
				this.setState({Loading:false});

					if(Props.driverassigned[0].status === "success"){
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.driverassigned[0].message+'</h3></div>');						
						setTimeout(
						function() {
							$('.success_message').html('');
							this.setState({selectedDriver:''});
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.driverassigned[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}

   }


  
  setselecteddistance(selectsers){
	const driverArray = selectsers.map ((distancType, index) => ({
     id: index,
     name: distancType,
    }));
	 let driversDistanc = [];
		driverArray.map(item =>
		//driversDistanc.push({ label: item.name }),
		//driversDistanc.push({<li>item.name</li>}),
		driversDistanc.push(<li>{item.name}</li>),
    );
	
    this.setState({driverdistancelist : driversDistanc})
	 console.log(driversDistanc);
	 
  }
  
   handleChangeDriver = selectedDriver => {
      this.setState({ selectedDriver});
      this.setState({ drivervalue : selectedDriver.value });
      if(selectedDriver.value === ''){
        $('.errordriverlist').html('<span class="errorspan">Please fill the field</span>');
      }
   }
   handleFormSubmit = () => {
		if(this.validateForm()){ console.log('in validated');
			var qs = require('qs');
			var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					rideId: this.state.rideId,					
					driverId: this.state.drivervalue,					
					
				};
				this.props.getAssignDriver(qs.stringify(postObject));
		}
   }
   validateForm() {
	   const {selectedDriver} = this.state;
	   let errors = 0;
	   if (selectedDriver.value === undefined || selectedDriver.value === '') {
		$('.errordriverlist').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errordriverlist').html('');
		}
		
		if(errors>0) {
			return false;
		}
		else {
			return true;
		}

   }
  render() {
     const driverArray = this.state.driverlist.map ((driverType, index) => ({
     id: index,
     name: driverType,
    }));
    // Dynamically create select list
    let drivers = [];
    driverArray.map(item =>
    drivers.push({ label: item.name.username, value: item.name.id }),
    );
	
   const { selectedDriver } = this.state;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="bookinghistory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
               <div className="form-wrapper">
			   <div className="success_message"></div>
                <div className="title">
                  <h3>Assign Ride</h3>
                </div>
                <div className="form-row ongoing-wrapper">
                    <div className="form-left">
						  <p><b>User Name: </b>{this.state.ongoingrideview.firstname}</p>
						  <p><b>Contact: </b>{this.state.ongoingrideview.mobileno}</p>
                    </div>      
                    <div className="form-right">                     
						  <p><b>From Location: </b>{this.state.ongoingrideview.from_location} </p>
						  <p><b>To Location: </b>{this.state.ongoingrideview.to_location}</p>
                    </div>
				 </div>	
				  <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
				 <div className="form-row">	
					<div className="form-left">
						<div className="form-group ">
							<label>Driver List:</label>
							 <Select 
								 options={drivers}  
								 value={selectedDriver?selectedDriver:{ value: '0', label: 'Select Driver' }}
								 onChange={this.handleChangeDriver}
								 placeholder="Select Driver" />
							<div className="errordriverlist"></div>
						</div>
					</div>
					{this.state.driverdistancelist!=''?
					<div className="form-right">
						<div className="neardrive-wrapper">
							<h3>Near by Drivers</h3>
							<ul>
							{this.state.driverdistancelist}
							</ul>
						</div>
					</div>:''}
                 </div>  
				 <div className="btn-group export">	
					  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
											<span className="load-data"></span> 
											}Assign
					   </button>
				</div>
                 </form> 
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    ongoingrideview: state.ongoingrideview,
	driverdropdownlist : state.driverdropdownlist,
	driverassigned:state.driverassigned
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	getDriverDropDownList: (adminID,rideId) =>{
       dispatch({ type: GET_DRIVERDROPDOWNLIST, adminID,rideId});
    },
    getViewOngoingride: (rideId) =>{
       dispatch({ type: GET_VIEWONGOINGRIDE,rideId });
    },
	getAssignDriver: (formval) =>{
       dispatch({ type: GET_ASSIGNDRIVER,formval });
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Assign));