import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";

import {GET_ADDGROUP, GET_USERLISTBYPLUGIN }  from '../../actions'; 

class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			group_name:'',			
			user_ids:'',
			group_status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false,
			admin_id: localStorage.getItem('admin_id'),
			userlist:[],
            selecteduserid:null,
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.props.getUserListByPlugin(localStorage.getItem('admin_id'));
		

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });


	}
  

	componentDidMount() {
		document.title = PageTitle('Group Add');
    }

	

        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;

				const userids = formPayload.selecteduserid;
				var elements=[];
				let userList  = ''
				if(userids!=='' && typeof userids!==undefined && userids!==null) {
				userList = userids.map((number) =>
					elements.push(number.value)
				);
				}

				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					group_name:formPayload.group_name,			
					user_ids:elements,
					group_status:status,
					
				};

				this.props.getAddGroup(qs.stringify(postObject));
			}
	}

	
	 
	validateForm() {
		const {group_name,selecteduserid,group_status} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!group_name) {
			formIsValid = false;
			$('.errorgroup_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(group_name){
			$('.errorgroup_name').html('');
		}
		
		if ((selecteduserid === ''  || selecteduserid ===null || Object.keys(selecteduserid).length === 0) && this.state.checked==false) {
		formIsValid = false;
		$('.erroruser_ids').html('<span class="errorspan">Please select available users</span>');
		}else if(selecteduserid!=''){
		$('.erroruser_ids').html('');
		}

		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.groupadd !== this.props.groupdd){
    		if(Object.keys(Props.groupadd).length > 0){
				this.setState({Loading:false});

					if(Props.groupadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.groupadd[0].message+'</h3></div>');
						this.setState({
							group_name:'',
							selecteduserid:'',
							group_status:'',
							selectedvalue: ''
						});
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.groupadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    	 if(Props.groupuserlist != this.props.groupuserlist){
    	 	if(Object.keys(Props.groupuserlist).length > 0){
    	 		const userList =  Props.groupuserlist[0].userlist;
    	 		console.log(userList,'userList')
    	 		this.setState({userlist:userList});
    	 	}
        }
    }


    handleUserChange = (event) => {
     this.setState({selecteduserid : event}, function() {
            const user_list = this.state.selecteduserid;
          
       });

    };

  handleMenuClose = () => {
    const membersToRender = this.state.userlist.filter(member => member.value)
    const numRows = membersToRender.length
    	const userList = [];
          this.state.userlist.map(function(item){  
            userList.push({
                  value: item.value,
                  label: item.label
              });
          });
          this.setState({userlist:userList});
    
    }
	
  render() {
  	  const {selectedOption,selecteduserid} = this.state;
  	  console.log(selecteduserid,'selecteduserid')
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="group" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Group</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				
				<div className="form-group">
					<label>Group Name</label>
				    <input type="text" name="group_name" onChange={this.handleInputChange} className="form-control" value={this.state.group_name} />
					<div className="errorgroup_name"></div>
				</div>

				<div className="form-group">
					<label>Users:</label>
				     <Select 
	                     options={this.state.userlist}
	                     value={this.state.selecteduserid}
	                     placeholder="Select Users for Group"
	                     onChange={this.handleUserChange}
	                     isMulti={true}
	                     withAll={true}
	                     onMenuClose={this.handleMenuClose}
	                     />
					<div className="erroruser_ids"></div>
				</div>

				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
				
				
			</div>
				
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	groupadd: state.groupadd,
  	groupuserlist: state.groupuserlist
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddGroup: (formPayload) =>{
       dispatch({ type: GET_ADDGROUP, formPayload});
    },
    getUserListByPlugin: (admin_id) =>{
       dispatch({ type: GET_USERLISTBYPLUGIN, admin_id});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));