import { SET_ROUTEDETAIL } from '../actions';

const routedetail = (state = [], action) => {
  switch (action.type) {
	case SET_ROUTEDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export default routedetail;
