import { SET_EVENTDETAIL } from '../actions';

const eventdetail = (state = [], action) => {
  switch (action.type) {
	case SET_EVENTDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export default eventdetail;
