/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDDRIVER, SET_ADDDRIVER, GET_DRIVERLIST,SET_DRIVERLIST,GET_EDITDRIVER,SET_EDITDRIVER,GET_DELETEDRIVER, SET_DELETEDRIVER, GET_ACTIVATEDRIVER, SET_ACTIVATEDRIVER, GET_EXPORTDRIVER, SET_EXPORTDRIVER, GET_DRIVERDETAIL, SET_DRIVERDETAIL, SET_LOCATEDDRIVERS, GET_LOCATEDDRIVERS } from '../actions';
import {apiUrl,sampleFileUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*get driver detail*/

export const watchGetDriverDetail = function* () {
  yield takeEvery(GET_DRIVERDETAIL, workerGetDriverDetail);
}

function* workerGetDriverDetail({ driverid }) {
  var url = apiUrl+'driver/driverdetails?id='+driverid;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_DRIVERDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

/*get driver list*/
export const watchGetDriverList = function* () {
  yield takeEvery(GET_DRIVERLIST, workerGetDriverList);
}

function* workerGetDriverList({ formVal }) {
  try {
      
 const result = yield call(getDriverList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DRIVERLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDriverList(formPayload) {
   return Axios.post(apiUrl+'driver/list', formPayload);
} 

/*save the user*/

export const watchGetAddDriver = function* () {
  yield takeEvery(GET_ADDDRIVER, workerGetAddDriver);
}

function* workerGetAddDriver({ formPayload }) {
  try {
      
 const result = yield call(getAddDriver, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDDRIVER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddDriver(formPayload) {
   return Axios.post(apiUrl+'driver/add', formPayload);
} 


/*edit the user*/

export const watchGetEditDriver = function* () {
  yield takeEvery(GET_EDITDRIVER, workerGetEditDriver);
}

function* workerGetEditDriver({ formPayload }) {
  try {
      
 const result = yield call(getEditDriver, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITDRIVER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditDriver(formPayload) {
   return Axios.post(apiUrl+'driver/edit', formPayload);
} 


/*delete the user*/

export const watchGetDeleteDriver = function* () {
  yield takeEvery(GET_DELETEDRIVER, workerGetDeleteDriver);
}

function* workerGetDeleteDriver({ formPayload }) {
  try {
      
 const result = yield call(getDeleteDriver, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEDRIVER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteDriver(formPayload) { console.log('test');
   return Axios.post(apiUrl+'driver/delete', formPayload);
} 


/*change status the user*/

export const watchActivateDriver = function* () {
  yield takeEvery(GET_ACTIVATEDRIVER, workerActivateDriver);
}

function* workerActivateDriver({ formPayload }) {
  try {
      
 const result = yield call(getActivateDriver, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATEDRIVER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getActivateDriver(formPayload) {
   return Axios.post(apiUrl+'driver/change_status', formPayload);
} 


/*Export the user*/

export const watchExportDriver = function* () {
  yield takeEvery(GET_EXPORTDRIVER, workerExportDriver);
}

function* workerExportDriver({ formPayload }) {
  try {
      
 const result = yield call(getExportDriver, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EXPORTDRIVER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

function getExportDriver(formPayload) {
   return Axios.post(apiUrl+'driver/export', formPayload);
}


/*Locate Drivers*/
export const watchGetLocatedDrivers = function* () {
  yield takeEvery(GET_LOCATEDDRIVERS, workerGetLocatedDrivers);
}

function* workerGetLocatedDrivers() {
  try {
  var resultArr = [];
    yield put({ type: SET_LOCATEDDRIVERS, resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 
