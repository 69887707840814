import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";

import {GET_ADDHELPER }  from '../../actions'; 

class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			helper_username:'',			
			helper_lname:'',
			helper_fname:'',
			helper_contact:'',
			helper_email_address:'',
			helper_status:'',
			user_password: '',
            user_confirm_password: '',
            new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
			selectedDomain:'',
			domainvalue:'',
			domainlist:[],
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
		this.handleChange = this.handleChange.bind(this);
		
		

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		if(name == 'password'){
			this.setState({disablepasssword: false})
		}


	}
  

	componentDidMount() {
		document.title = PageTitle('Helper Add');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
			}
		var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'user/getdomainlist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

					console.log(res.data);
				
					this.setState({
						domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
					})
				
				}else{
				//console.log("test")
				}
			});
    }

	new_pass_show_hide = () => {
		this.setState({
			 new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			 pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
		}
		
		confim_pass_show_hide = () => {
		this.setState({
			 confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
			 confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
			});
		} 
	

        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					domain_id : formPayload.domain_id,
					helper_username:formPayload.helper_username,			
					helper_lname:formPayload.helper_lname,
					helper_fname:formPayload.helper_fname,
					helper_contact:formPayload.helper_contact,
					helper_email_address:formPayload.helper_email_address,
					password     : formPayload.user_password,
					confirm_password:formPayload.user_confirm_password, 
					helper_status:status,
					
				};

				console.log('postObject',postObject);
				this.props.getAddHelper(qs.stringify(postObject));
			}
	}


	handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');

		this.setState({
				domain_id: key,
				
		})


	}

	
	 
	validateForm() {
		const {helper_username,domain_id,helper_lname,helper_fname,helper_contact,helper_email_address,helper_status,user_password, user_confirm_password} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!helper_username) {
			formIsValid = false;
			$('.errorhelper_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_username){
			$('.errorhelper_username').html('');
		}

		if (!domain_id) {
			formIsValid = false;
			$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(domain_id){
			$('.errorassign_domain').html('');
		}

		
		if (!helper_lname) {
			formIsValid = false;
			$('.errorhelper_lname').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_lname){
			$('.errorhelper_lname').html('');
		}		
		

		if (!helper_fname) {
			formIsValid = false;
			$('.errorhelper_fname').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_fname){
			$('.errorhelper_fname').html('');
		}

		if (!helper_contact) {
			formIsValid = false;
			$('.errorhelper_contact').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_contact){
			$('.errorhelper_contact').html('');
		}

		if (!helper_email_address) {
			formIsValid = false;
			$('.errorhelper_email_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(helper_email_address){
			$('.errorhelper_email_address').html('');
		}


		
		if (!user_password) {
			errors++;
			$('.errorpassword').html('<span class="errorspan">Please fill the field</span>');
		}else if(user_password){
		 if (user_password.match(/[a-z]/g) && user_password.match( 
                    /[A-Z]/g) && user_password.match( 
                    /[0-9]/g) && user_password.match( 
                    /[^a-zA-Z\d]/g) && user_password.length >= 8){
		 				$('.errorpassword').html('');
		 }else{
		 	errors++;
			$('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}

		if (!user_confirm_password) {
			errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Please fill the confirm password</span>');
		}else if(user_confirm_password){
		 if (user_confirm_password.match(/[a-z]/g) && user_confirm_password.match( 
                    /[A-Z]/g) && user_confirm_password.match( 
                    /[0-9]/g) && user_confirm_password.match( 
                    /[^a-zA-Z\d]/g) && user_confirm_password.length >= 8){
		 				$('.errorchangepaswd').html('');
		 }else{
		 	errors++;
			$('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		 }
		}
		if(user_password !== user_confirm_password){
          	errors++;
            $('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
        }


		
		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.helperadd !== this.props.helperadd){
    		if(Object.keys(Props.helperadd).length > 0){
				this.setState({Loading:false});

					if(Props.helperadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.helperadd[0].message+'</h3></div>');
						this.setState({
							helper_username:'',			
							helper_lname:'',
							helper_fname:'',
							helper_contact:'',
							helper_email_address:'',
							helper_status:'',
							user_password:'',
							user_confirm_password:'',
							selectedvalue: ''
						});
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.helperadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	
  render() {
  	  const {selectedOption,selectedDomain,domainlist} = this.state;
		var current = this;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="helper" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Helper</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				
				
				<div className="form-group">
					<label>First Name</label>
				    <input type="text" name="helper_fname" onChange={this.handleInputChange} className="form-control" value={this.state.helper_fname} autoComplete ="off" />
					<div className="errorhelper_fname"></div>
				</div>

				<div className="form-group">
					<label>Last Name:</label>
				    <input  type="text" name="helper_lname" onChange={this.handleInputChange} className="form-control" value={this.state.helper_lname} autoComplete="off"/>
					<div className="errorhelper_lname"></div>
				</div>


				
				<div className="form-group eye-pad">
                    <label>New Password</label>
                    <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleInputChange.bind(this)} autoComplete="off"/>
                    <span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                 </div>
                  <div className="errorpassword"></div>
			   	  <div className="form-group eye-pad">
                    <label>Confirm Password</label>
                    <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)} autoComplete="off"/>
                    <span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                   <div className="errorchangepaswd"></div>

				   <div className="form-group">					
					<label>Assign Domain : </label>
					<select  onChange={current.handleClickSelecet}  value={domainlist.domain_name}>                    
        {this.state.domainlist.map((domainlist) => <option key={domainlist.value} data-key={domainlist.value} value={domainlist.value} >{domainlist.label}</option>)}
            </select>
						
					<div className="errorassign_domain"></div>
				</div>
				
				
			</div>
			<div className="form-right">


				<div className="form-group">
					<label>User Name:</label>
				    <input type="text" name="helper_username" onChange={this.handleInputChange} className="form-control" value={this.state.helper_username} autoComplete ="off"/>
					<div className="errorhelper_username"></div>
				</div>	
			
				<div className="form-group">
					<label>Helper Email:</label>
				    <input type="text" name="helper_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.helper_email_address} autoComplete="off"/>
					<div className="errorhelper_email_address"></div>
				</div>

				<div className="form-group">
					<label>Helper Phone Number:</label>
						<input type="text" name="helper_contact" onChange={this.handleInputChange} className="form-control" value={this.state.helper_contact} autoComplete="off"/>
					<div className="errorhelper_contact"></div>
				</div>

				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>

			
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	helperadd: state.helperadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddHelper: (formPayload) =>{
       dispatch({ type: GET_ADDHELPER, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));