import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";

import Geocode from "react-geocode";

import {GET_ADDCOMMENT }  from '../../actions'; 

class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			comment_name:'',
			status:'',
			comments:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

    }


	componentDidMount() {
		document.title = PageTitle('Comment Add');
    }

	


      handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					comments:formPayload.comments,
					status:status,
					
				};
				this.props.getAddComment(qs.stringify(postObject));
			}
	}

	
	 
	validateForm() {
		const {comments} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		
		if (!comments) {
			formIsValid = false;
			$('.error_comments').html('<span class="errorspan">Please enter the comments</span>');
		}else if(comments){
			$('.error_comments').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.commentadd !== this.props.commentadd){
    		if(Object.keys(Props.commentadd).length > 0){
				this.setState({Loading:false});

					if(Props.commentadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.commentadd[0].message+'</h3></div>');
						this.setState({
							comments:'',
							status:'',
							selectedvalue: ''
						});
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.commentadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="comments" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Comments</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Comments:</label>
				    <input placeholder="Enter a  comments" type="text" name="comments" onChange={this.handleInputChange} className="form-control" value={this.state.comments} />
					<div className="error_comments	"></div>
				</div>
				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
				
				{/*<div className="form-group">
					<label>Route End Location:</label>
				    <input id="to_loc" placeholder="Enter a End location" type="text" name="r_end_point" onChange={this.handleInputChange} className="form-control" value={this.state.r_end_point} />
					<div className="errorr_end_point	"></div>
	          </div> */}

				
			</div>
			<div className="form-right">	
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	commentadd: state.commentadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddComment: (formPayload) =>{
       dispatch({ type: GET_ADDCOMMENT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));