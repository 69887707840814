import { SET_ROUTELIST } from '../actions';

const routelist = (state = [], action) => {
  switch (action.type) {
	case SET_ROUTELIST:
      return [...action.value];  
    default: return state;
  }
}

export default routelist;
