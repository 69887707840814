/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_BOOKINGHISTORY, SET_BOOKINGHISTORY,GET_ONGOINGRIDE ,SET_ONGOINGRIDE,GET_VIEWONGOINGRIDE ,SET_VIEWONGOINGRIDE, GET_COMPLETEDRIDE, SET_COMPLETEDRIDE, GET_INCOMPLETERIDE, SET_INCOMPLETERIDE, GET_DRIVERDROPDOWNLIST, SET_DRIVERDROPDOWNLIST,GET_ASSIGNDRIVER,SET_ASSIGNDRIVER } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*list*/
export const watchGetBookingHistory = function* () {
  yield takeEvery(GET_BOOKINGHISTORY, workerGetBookingHistory);
}

function* workerGetBookingHistory({ formVal }) {
  try {
    console.log(formVal)
    const result = yield call(getBookingHistory, formVal);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_BOOKINGHISTORY, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getBookingHistory(formVal){
   return Axios.post(apiUrl+'booking_admin/list', formVal);
}


/*ongoing list*/
export const watchGetOngoingRide = function* () {
  yield takeEvery(GET_ONGOINGRIDE, workerGetOngoingRide);
}

function* workerGetOngoingRide({ formVal }) {
  try {
    const result = yield call(getOngoingRide, formVal);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ONGOINGRIDE, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getOngoingRide(formVal){
   return Axios.post(apiUrl+'booking_admin/ongoing_ride', formVal);
}


/*ongoing list of driver*/
export const watchGetViewOngoingride = function* () {
  yield takeEvery(GET_VIEWONGOINGRIDE, workerGetViewOngoingride);
}

function* workerGetViewOngoingride({ rideId }) {
  try {
    const result = yield call(getViewOngoingride, rideId);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VIEWONGOINGRIDE, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getViewOngoingride(rideId){
   return Axios.post(apiUrl+'booking_admin/view', rideId);
}


/*completed list*/
export const watchGetCompletedRide = function* () {
  yield takeEvery(GET_COMPLETEDRIDE, workerGetCompletedRide);
}

function* workerGetCompletedRide({ formVal }) {
  try {
    console.log(formVal)
    const result = yield call(getCompletedRide, formVal);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_COMPLETEDRIDE, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getCompletedRide(formVal){
   return Axios.post(apiUrl+'booking_admin/completed_event', formVal);
}

/*incompleted list*/
export const watchGetIncompleteRide = function* () {
  yield takeEvery(GET_INCOMPLETERIDE, workerGetIncompleteRide);
}

function* workerGetIncompleteRide({ formVal }) {
  try {
    console.log(formVal)
    const result = yield call(getIncompleteRide, formVal);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_INCOMPLETERIDE, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

function getIncompleteRide(formVal){
   return Axios.post(apiUrl+'booking_admin/all_ride_list', formVal);
}



export const watchGetDriverDropList = function* () {
  yield takeEvery(GET_DRIVERDROPDOWNLIST, workerGetDriverDropList);
}

function* workerGetDriverDropList({ adminID,rideId }) { console.log(rideId)
  var url = apiUrl+'booking_admin/dropdowndriverlist?adminID='+adminID+'&rideId='+rideId;
  console.log(url)
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_DRIVERDROPDOWNLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} 

/*Assign Company*/

export const watchGetAssignDriver = function* () {
  yield takeEvery(GET_ASSIGNDRIVER, workerGetAssignDriver);
}

function* workerGetAssignDriver({ formval }) {
  try {
      
 const result = yield call(getAssignDriver, formval);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ASSIGNDRIVER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAssignDriver(formval) {
   return Axios.post(apiUrl+'booking_admin/assign', formval);
}