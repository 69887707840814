import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';
import { GET_DRIVERLIST, GET_DELETEDRIVER, GET_ACTIVATEDRIVER, GET_EXPORTDRIVER } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      driverlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:''
    };
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id
      };
      this.props.getDriverList(qs.stringify(postobject));

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
    //this.driverlist();
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});

      var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");

      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      this.props.getDriverList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
           var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
            activePage   : 1,
            search_all : formPayload.search_all,
            admin_id: admin_id         
          };
          console.log(postObject);
          this.props.getDriverList(qs.stringify(postObject));
        
    } 

   componentWillReceiveProps(Props){
   
    if (Object.keys(Props.driverlist).length > 0) {
      if (Props.driverlist[0]["status"] === "success") {
     
         this.setState({ totalRecord : Props.driverlist[0].records_count, driverlist: Props.driverlist[0]["driverlist"] }, function() {
            this.driverlist();
          });

      }
    }
     
	if(Props.driverstatus !== this.props.driverstatus){
      if (Props.driverstatus[0]["status"] === "success") {
          var qs = require('qs');
          var admin_id = localStorage.getItem("admin_id");
          var postobject = {
          activePage: this.state.activePage,
          search_all: '',
          admin_id: admin_id
          };
          this.props.getDriverList(qs.stringify(postobject));
      }
    }
	if(Props.exportdriver !== this.props.exportdriver){
		
      if (Props.exportdriver[0]["status"] === "success") {
		  let url =baseUrl+"/"+Props.exportdriver[0]["message"];
          window.open(url,
  '_blank' // <- This is what makes it open in a new window.
);
      }
    }
	if (Props.driverdelete!== this.props.driverdelete) {
      if (Props.driverdelete[0]["status"] === "success") {
          this.setState({
          driverlist: Props.driverdelete[0]["driverlist"],
          });
          }
          window.location.reload(); 
      }
	  
   }

deleteuser(id){
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id
        };
      this.props.getDeleteUser(qs.stringify(postObject));
}

activateuser(id, status){
	let stat = ''
		if(status == 'Inactive'){
			stat = "A";
		}else{
			stat = "I";
		}
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          user_id  :id,
		      user_status: stat
        };
      this.props.getActivateDriver(qs.stringify(postObject));
}

exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
      this.props.getExportDriver(qs.stringify(postObject));
}
deletedriver(id){ console.log(id);
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          driver_id  :id,
          admin_id  : admin_id,
		   activePage: this.state.activePage,
			search_all: this.state.search_all,
        };
      this.props.getDeleteDriver(qs.stringify(postObject));
}
  driverlist() {
    var driverlist = this.props.driverlist[0];
    if (driverlist != "undefined" && driverlist != null) {
      if (driverlist.status == "success") {
        if (Object.keys(driverlist).length > 0) {
          const userlistDetails = driverlist.driverlist.map(
            (driverlist, Index) => {
              let sno = Index+1;
              if(driverlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={driverlist.id}>
                <td>{sno}</td>
			         	 <td>
                  {driverlist.username}
                  </td>
                  <td>
                  {driverlist.firstname}
                  </td>
                  <td>{driverlist.lastname}</td>
                  <td>{driverlist.email}</td>
				          <td>{driverlist.mobileno}</td>
                  <td>{status}</td>
                  <td>
                  {(driverlist.driver_verify == 'A'?<i className="fa fa-check" aria-hidden="true"></i>:<i className="fa fa-times" aria-hidden="true"></i>)}          
                  </td>
                  <td className="actiongroup">
                     <Link to={{ pathname: '/editdriver/'+driverlist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>

                      {(status == 'Active'?<Link onClick={this.activateuser.bind(this, driverlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, driverlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}          
					 <Link onClick={this.deletedriver.bind(this,driverlist.id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
            

         {/*   <td className="">
                                <Link to={{ pathname: '/user/edit/'+driverlist.id}} className="" title="Duplicate">  <i className="fa fa-edit" aria-hidden="true"></i></Link>

				   {(status == 'Active'?<Link onClick={this.activateuser.bind(this, driverlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, driverlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}				   
           </td>*/}
                </tr>
              );
            }
          );
          return userlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="10" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="driveruser" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div className="car-listing-wrap">

        <div className="listing-header"> 
          <div className="title">
            <h3>Drivers</h3>
          </div> 
           <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
          <div className="add_button">  
          <a className="" href='/driver-add'>Add &nbsp;|&nbsp;</a>
 <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="fa fa-download" aria-hidden="true"></i></Link>
		  
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			    <th>User Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
				        <th>Contact</th>
                <th>Status</th>
                <th>Verified by Admin</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.driverlist()}</tbody>
          </Table>
           <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
  driverlist: state.driverlist,
  driverstatus:state.driverstatus,
  exportdriver: state.exportdriver,
  driverdelete: state.driverdelete
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDriverList: (formVal) =>{
       dispatch({ type: GET_DRIVERLIST, formVal});
    },
    getActivateDriver: (formPayload)=> {
      dispatch({ type: GET_ACTIVATEDRIVER, formPayload });
    },
   getExportDriver: (formPayload) => {
     dispatch({ type: GET_EXPORTDRIVER, formPayload });
	},
	getDeleteDriver: (formPayload) => { console.log(formPayload);
      dispatch({type: GET_DELETEDRIVER, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));