import { SET_ONGOINGRIDE } from '../actions';

const ongoingride = (state = [], action) => {
  switch (action.type) {
	case SET_ONGOINGRIDE:
      return [...action.value];  
    default: return state;
  }
}

export default ongoingride;
