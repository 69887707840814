import { SET_DELETEROUTE } from '../actions';

const deleteroute = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEROUTE:
      return [...action.value];  
    default: return state;
  }
}

export default deleteroute;
