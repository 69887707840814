import { SET_ADDVEHICLE } from '../actions';

const vehicleadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDVEHICLE:
      return [...action.value];  
    default: return state;
  }
}

export default vehicleadd;
