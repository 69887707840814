/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDEVENT, SET_ADDEVENT, GET_EVENTDETAIL ,SET_EVENTDETAIL,GET_EVENTLIST,SET_EVENTLIST,GET_EDITEVENT,SET_EDITEVENT,GET_DELETEEVENT, SET_DELETEEVENT } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*list*/
export const watchGetEventList = function* () {
  yield takeEvery(GET_EVENTLIST, workerGetEventList);
}

function* workerGetEventList({ formVal }) {
  try {
      
 const result = yield call(getEventList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EVENTLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEventList(formVal) {
   return Axios.post(apiUrl+'event/list', formVal);
} 



/*save the Event*/

export const watchGetAddEvent = function* () {
  yield takeEvery(GET_ADDEVENT, workerGetAddEvent);
}

function* workerGetAddEvent({ formPayload }) {
  try {
      
 const result = yield call(getAddEvent, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDEVENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddEvent(formPayload) {
 //console.log('kskdksdsd',formPayload);
   return Axios.post(apiUrl+'event/add', formPayload);
} 


/*edit the event*/

export const watchGetEditEvent = function* () {
  yield takeEvery(GET_EDITEVENT, workerGetEditEvent);
}

function* workerGetEditEvent({ formPayload }) {
  try {
      
 const result = yield call(getEditEvent, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITEVENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditEvent(formPayload) {
   return Axios.post(apiUrl+'event/edit', formPayload);
}


/*delete the event*/

export const watchGetDeleteEvent = function* () {
  yield takeEvery(GET_DELETEEVENT, workerGetDeleteEvent);
}

function* workerGetDeleteEvent({ formPayload }) {
  try {
      
 const result = yield call(getDeleteEvent, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEEVENT, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteEvent(formPayload) {
  return Axios.post(apiUrl+'event/delete', formPayload);
} 



/*get Event detail*/

export const watchGetEventDetail = function* () {
  yield takeEvery(GET_EVENTDETAIL, workerGetEventDetail);
}

function* workerGetEventDetail({ eventId, adminId }) {
  var url = apiUrl+'event/eventdetail?id='+eventId+'&admin_id='+adminId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_EVENTDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  
