import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'active'
            }else{
            status = 'enable';
        }
        return status;
    }
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu">
	   <Scrollbars className="custom-scroll-wrap">
			<ul className="menulist">
				<li className={this.activeMenu('dashboard')}>
					<a title="Dashboard" href="#">
						<i className="dashboard-icon icon"></i><span>Dashboard</span>
					</a>
				</li>	
				{/*<li>
					<a title="Documents" href="javascript:void(0)" className="dropdown-toggle">
						<i className="docs-icon icon" aria-hidden="true"></i>
						<span>Documents</span>
					</a>
					<ul className="submenu dropdown-menu">
						<li>
							<a href="#" title="Upload Docs">Documents 1</a>
						</li>
						<li>
							<a href="#" title="Modify Docs">Documents 2</a>
						</li>
					</ul>
				</li>*/}

				<li className={this.activeMenu('user')}>
					<a title="Users" href="/user">
						<i className="userlist-icon icon" aria-hidden="true"></i>
						<span>Users</span>
					</a>
				</li>
				<li className={this.activeMenu('driveruser')}>
					<a title="Drivers" href="/driveruser">
						<i className="driver-icon icon" aria-hidden="true"></i>
						<span>Drivers</span>
					</a>
				</li>
				{this.state.admin_id == 1 ?<li className={this.activeMenu('companylist')}>
					<a title="Company" href="/company">
						<i className="company-icon icon" aria-hidden="true"></i>
						<span>Company</span>
					</a>
				</li>:''}

				<li className={this.activeMenu('helper')}>
					<a title="Helpers" href="/helper">
						<i className="helpers-icon icon" aria-hidden="true"></i>
						<span>Helpers</span>
					</a>
				</li>

				<li className={this.activeMenu('group')}>
					<a title="Groups" href="/group">
						<i className="groups-icon icon" aria-hidden="true"></i>
						<span>Groups</span>
					</a>
				</li>

				<li className={this.activeMenu('event')}>
					<a title="Events" href="/event">
						<i className="events-icon icon" aria-hidden="true"></i>
						<span>Events</span>
					</a>
				</li>
			   {/*	<li className={this.activeMenu('route')}>
					<a title="Routes" href="/route">
						<i className="routes-icon icon" aria-hidden="true"></i>
						<span>Routes</span>
					</a>
			    </li> */ }

				<li className={this.activeMenu('vehicle')}>
					<a title="Bus Types" href="/vehicle">
						<i className="vehicletype-icon icon"></i>
						<span>Bus Types</span>
					</a>
				</li>
				<li className={this.activeMenu('bus')}>
					<a title="Buses" href="/bus">
						<i className="vehicle-icon icon"></i>
						<span>Buses</span>
					</a>
				</li>
			    <li className={this.activeMenu('bookinghistory')}>
					<a title="Rides History" href="/bookinghistory">
						<i className="history-icon icon" aria-hidden="true"></i>
						<span>Rides History</span>
					</a>
				</li>
				<li className={this.activeMenu('ongoingride')}>
					<a title="Ongoing Rides" href="/ongoingride">
						<i className="vehicle-icon icon" aria-hidden="true"></i>
						<span>Ongoing Rides</span>
					</a>
				</li>

				<li className={this.activeMenu('completedride')}>
					<a title="Completed Rides" href="/completedride">
						<i className="completed-icon icon" aria-hidden="true"></i>
						<span>Completed Rides</span>
					</a>
				</li>

				{/*<li className={this.activeMenu('incompleteride')}>
					<a title="Incomplete Rides" href="/incompleteride">
						<i className="incompleted-icon icon" aria-hidden="true"></i>
						<span>Incomplete Rides</span>
					</a>
				</li>
				<li className={this.activeMenu('fonts')}>
					<a title="Fonts" href="/fonts">
						<i className="font-icon icon" aria-hidden="true"></i>
						<span>Fonts</span>
					</a>
				</li>*/}
				<li className={this.activeMenu('comments')}>
					<a title="Comments" href="/comments">
						<i className="comments-icon icon" aria-hidden="true"></i>
						<span>Comments</span>
					</a>
				</li>
				<li className={this.activeMenu('locatedrivers')}>
					<a title="Locate Drivers" href="/locatedrivers">
						<i className="locate-icon icon" aria-hidden="true"></i>
						<span>Locate Drivers</span>
					</a>
				</li>
				<li className={this.activeMenu('templates')}>
					<a title="Templates" href="/templates">
						<i className="template-icon icon" aria-hidden="true"></i>
						<span>Templates</span>
					</a>
				</li>
				<li className={this.activeMenu('setting')}>
					<a title="Settings" href="/setting">
						<i className="setting-icon icon" aria-hidden="true"></i>
						<span>Settings</span>
					</a>
				</li>
				<li className={this.activeMenu('paymentlog')}>
					<a title="Payment" href="/paymentlog">
						<i className="payment-icon icon" aria-hidden="true"></i>
						<span>Tip Payment</span>
					</a>
				</li>
			</ul>
			</Scrollbars>
		</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;