import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

import Geocode from "react-geocode";
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";


import {GET_EDITEVENT,GET_EVENTDETAIL,GET_EVENTLIST}  from '../../actions'; 

import {apiUrl } from "../../components/Config/Config";
const axios = require("axios");

class Edit extends Component {
	
	constructor(props)
	{
		
		super(props);	
	    const eventId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			event_name:'',			
			event_description:'',
			event_location:'',
			event_start_date:'',
			event_end_date:'',
			event_status:'',
			event_type:'',
			e_start_point:'',
			e_end_point:'',
			assign_stop:'',
			no_of_passengers:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			event_id: eventId,
			Loading:false,
			admin_id: localStorage.getItem('admin_id'),
			selectedRoute:'',
			routevalue:'',
			routelist:[],
			selectedDriver:'',
			drivervalue:'',
			driverlist:[],
			selectedBus:'',
			busvalue:'',
			buslist:[],
			selectedGroup:'',
			groupvalue:'',
			grouplist:[],
			selectedHelper:'',
			helpervalue:'',
			helperlist:[],
			stopplacename:'',
		    eventtype: [
            { value: 'Trekking', label: 'Trekking' },
            { value: 'School', label: 'School' },
            { value: 'Trip', label: 'Trip' }
       		],

		};
		this.props.getEventDetail(eventId, localStorage.getItem('admin_id'));
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");
	 }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		const google = window.google;
		const callfunc = this;
			   if(name == 'e_start_point'){
				   var input = document.getElementById('from_loc');
				   var autocomplete = new google.maps.places.Autocomplete(input);
				   autocomplete.addListener('place_changed',function() {
				   document.getElementById("location-error").style.display = 'none';
				   var place = autocomplete.getPlace();
				   var locationAddress1 = place.name;
				   var locationAddress = place.formatted_address;
				   var fullplace = place.name+','+locationAddress;
				   callfunc.setState({e_start_point: locationAddress})
				   callfunc.getgoecoder(locationAddress,input.name);
				   callfunc.setState({fulldroploc : fullplace});
				   });
			   }

			   if(name == 'e_end_point'){

					var input = document.getElementById('to_loc');
					var autocomplete = new google.maps.places.Autocomplete(input);
					autocomplete.addListener('place_changed',function() {
					document.getElementById("location-error").style.display = 'none';
					var place = autocomplete.getPlace();
					var locationAddress1 = place.name;
					var locationAddress = place.formatted_address;
					var fullplace = place.name+','+locationAddress;
					callfunc.setState({e_end_point: locationAddress})
					callfunc.getgoecoder(locationAddress,input.name);
					callfunc.setState({fulldroploc : fullplace});
					});

			  }

			if(name == 'event_location'){
					var input = document.getElementById('place_event');
					var autocomplete = new google.maps.places.Autocomplete(input);
					autocomplete.addListener('place_changed',function() {
					document.getElementById("location-error").style.display = 'none';
					var place = autocomplete.getPlace();
					var locationAddress1 = place.name;
					var locationAddress = place.formatted_address;
					var fullplace = place.name+','+locationAddress;
					callfunc.setState({event_location: locationAddress})
					callfunc.getgoecoder(locationAddress,input.name);
					callfunc.setState({fulldroploc : fullplace});
					});
			}

			if(name == 'assign_stop'){
					var input = document.getElementById('assign_stop');
					var autocomplete = new google.maps.places.Autocomplete(input);
					autocomplete.addListener('place_changed',function() {
					document.getElementById("location-error").style.display = 'none';
					var place = autocomplete.getPlace();
					var locationAddress1 = place.name;
					var locationAddress = place.formatted_address;
					var fullplace = place.name+','+locationAddress;
					callfunc.setState({stopplacename: locationAddress1})
					callfunc.getgoecoder(locationAddress,input.name);
					callfunc.setState({fulldroploc : fullplace});
					});
			}

			
    }

			
	getgoecoder(input, name){

		$('#from_loc').val('');

		$('#to_loc').val('');

		$('#place_event').val('');

		$('#assign_stop').val('');
			
		if(name == 'e_start_point'){ 

			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;
				this.setState({from_long : lng, from_lat: lat, e_start_point:response.results[0].formatted_address});
				
				document.getElementById('from_loc').value = response.results[0].formatted_address;
				$('#from_loc').val(response.results[0].formatted_address);					  
			},
			error => {
				console.error(error);
			  }
			);

		}else if(name == 'e_end_point'){ 

			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					to_long : lng,
					to_lat: lat,
					e_end_point: response.results[0].formatted_address
				});	
				
				document.getElementById('to_loc').value = response.results[0].formatted_address;
				$('#to_loc').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);

		}else if(name == 'event_location'){ 

			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					event_location: response.results[0].formatted_address
				});	
				
				document.getElementById('place_event').value = response.results[0].formatted_address;
				$('#place_event').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}else if(name == 'assign_stop'){

			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;	
				this.setState({
					assign_stop: response.results[0].formatted_address
				});	

				this.setState({assign_stop_long : lng, assign_stop_lat: lat, assign_stop:response.results[0].formatted_address});

				
				document.getElementById('assign_stop').value = response.results[0].formatted_address;
				$('#assign_stop').val(response.results[0].formatted_address);	
				
				const eventId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
				var qs = require('qs');

				var postObject = {
			    admin_id : localStorage.getItem("admin_id"),	
				id: eventId,
				assign_stop_place_name:this.state.stopplacename,
				assign_stop_name:this.state.assign_stop,
				assign_stop_long:this.state.assign_stop_long,
				assign_stop_lat:this.state.assign_stop_lat
				};
				
				axios.post(apiUrl+"event/eventassignstop",qs.stringify(postObject)).then(res => {
				if(res.data.status === "success"){
				//console.log('asasasas',res.data.eventlist);
				if(res.data.eventlist!='undefined'){
					this.setState({stop_lists: res.data.eventlist,stopplacename:''});
				}


				}else{	
				//console.log("test")	
				}	
				});
				this.setState({assign_stop: ''});
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}

	}
   keyPressed(event) {
	 if (event.key === "Enter") {
	 localStorage.setItem('setkeyword',event.target.value);
	  setTimeout(() => {
			$('#clickToggle').trigger('click');
			document.getElementById("from_loc").value = "";
		 }, 500);
	}
	}

	componentDidMount() {
		
		document.title = PageTitle('Event Edit');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
			}
			
		const eventId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		var qs = require('qs');
			var postObject = {
		    admin_id : localStorage.getItem("admin_id"),	
			id: eventId,
			};
			
			axios.post(apiUrl+"event/eventassignstoplist",qs.stringify(postObject)).then(res => {
				if(res.data.status === "success"){	
				
				if(res.data.eventlist!='undefined'){
           			this.setState({stop_lists: res.data.eventlist});
				}
					
				}else{	
				//console.log("test")	
				}	
				});
    }

	onValueChangeposition = (event) => {
		console.log(event,'event')
		if(event.value != ''){
			var event_type = event.value;
		}
		localStorage.setItem('event_type', event_type);
	
	   this.setState({
			event_type: {value: event.value, label: event.value}
		});
		
	  }


        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;

				var qs = require('qs');
				var status = '';

				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var assigndriver;
				if(formPayload.selectedDriver !== ''){
				assigndriver =formPayload.selectedDriver.value;
				}else{
				assigndriver =formPayload.drivervalue;
				}

				var assignbus;
				if(formPayload.selectedBus !== ''){
				assignbus =formPayload.selectedBus.value;
				}else{
				assignbus =formPayload.busvalue;
				}


				var assigngroup;
				if(formPayload.selectedGroup !== ''){
				assigngroup =formPayload.selectedGroup.value;
				}else{
				assigngroup =formPayload.groupvalue;
				}


				var assignhelper;
				if(formPayload.selectedHelper !== ''){
				assignhelper =formPayload.selectedHelper.value;
				}else{
				assignhelper =formPayload.helpervalue;
				}


			    var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					event_id : formPayload.event_id,
					event_name:formPayload.event_name,			
					event_description:formPayload.event_description,
					event_location:formPayload.event_location,
					event_start_date:formPayload.event_start_date,
					event_end_date:formPayload.event_end_date,
					event_type:formPayload.event_type.value,
					e_start_point:formPayload.e_start_point,
					e_end_point:formPayload.e_end_point,
					to_long:formPayload.to_long,
					to_lat:formPayload.to_lat,
					from_lat:formPayload.from_lat,
					from_long:formPayload.from_long,
					no_of_passengers:formPayload.no_of_passengers,
					assign_group:assigngroup,
					assign_bus:assignbus,
					assign_driver:assigndriver,
					assign_helper :assignhelper,
					event_status:status
			};
				this.props.getEditEvent(qs.stringify(postObject));
			}
	}

	validateForm() {
		
		const {event_name,event_description,no_of_passengers,e_end_point,event_location,event_start_date,event_end_date,event_type,e_start_point,selectedGroup, selectedDriver, selectedBus, selectedRoute, selectedHelper} = this.state;
		 let errors = 0;
	
	   if (!event_name) {
			errors++;
			$('.errorevent_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_name){
			$('.errorevent_name').html('');
		}
		
		if (!event_description) {
			errors++;
			$('.errorevent_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_description){
			$('.errorevent_description').html('');
		}		
		

		if (!event_location) {
			errors++;
			$('.errorevent_location').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_location){
			$('.errorevent_location').html('');
		}

		if (!event_start_date) {
			errors++;
			$('.errorevent_start_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_start_date){
			$('.errorevent_start_date').html('');
		}

		if (!event_end_date) {
			errors++;
			$('.errorevent_end_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_end_date){
			$('.errorevent_end_date').html('');
		}


		
		if (!event_type) {
			errors++;
			$('.errorevent_type').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_type){
			$('.errorevent_type').html('');
		}


		if (!e_start_point) {
			errors++;
			$('.errore_start_point').html('<span class="errorspan">Please fill the field</span>');
		}else if(e_start_point){
			$('.errore_start_point').html('');
		}

		if (!no_of_passengers) {
			errors++;
			$('.errorno_of_passengers').html('<span class="errorspan">Please fill the field</span>');
		}else if(no_of_passengers){
			$('.errorno_of_passengers').html('');
		}

		/*if (selectedRoute.value === undefined || selectedRoute.value === '') {
		$('.errorassign_route').html('<span class="errorspan">Please select the field</span>');
		errors++;
		}
		else {
		$('.errorassign_route').html('');
		}*/
		/*console.log(selectedRoute)
		if ((selectedRoute === ''  || selectedRoute ===null || Object.keys(selectedRoute).length === 0)) {
		errors++;
		$('.errorassign_route').html('<span class="errorspan">Please select available route</span>');
		}else if(selectedRoute!=''){
		$('.errorassign_route').html('');
		}*/


		if (selectedDriver.value === undefined || selectedDriver.value === '') {
		$('.errorassign_driver').html('<span class="errorspan">Please select the field</span>');
		errors++;
		}
		else {
		$('.errorassign_driver').html('');
		}

		if (selectedGroup.value === undefined || selectedGroup.value === '') {
		$('.errorassign_group').html('<span class="errorspan">Please select the field</span>');
		errors++;
		}
		else {
		$('.errorassign_group').html('');
		}

		if (selectedBus.value === undefined || selectedBus.value === '') {
		$('.errorassign_bus').html('<span class="errorspan">Please select the field</span>');
		errors++;
		}
		else {
		$('.errorassign_bus').html('');
		}


		if (selectedHelper.value === undefined || selectedHelper.value === '') {

		$('.errorassign_helper').html('<span class="errorspan">Please select the field</span>');
		errors++;
		}
		else {
		$('.errorassign_helper').html('');
		}
		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
		
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){

   	if(Props.eventdetail !== this.props.eventdetail){
    		if(Object.keys(Props.eventdetail).length > 0){
				this.setState({Loading:false});
					if(Props.eventdetail[0].status === "success"){

						const formdata = Props.eventdetail[0].eventlist;
						if(formdata.event_status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						
						this.setState({event_name:formdata.event_name});
						this.setState({event_description: formdata.event_description});
						this.setState({event_location: formdata.event_location});
						this.setState({event_start_date: formdata.event_start_date});
						this.setState({event_end_date: formdata.event_end_date});

						if(formdata.event_type == 'School'){
							this.setState({event_type:{ value: 'School', label: 'School'}});
						}else if(formdata.event_type == 'Trekking'){
							this.setState({event_type:{ value: 'Trekking', label: 'Trekking'}});
						}else if(formdata.event_type == 'Trip'){
							this.setState({event_type:{ value: 'Trip', label: 'Trip'}});
						}

						this.setState({e_start_point: formdata.e_start_point});
						this.setState({e_end_point: formdata.e_end_point});
						this.setState({no_of_passengers: formdata.no_of_passengers});
					
						
						this.setState({to_long: formdata.e_end_long});
						this.setState({to_lat: formdata.e_end_lat});
						this.setState({from_lat: formdata.e_start_lat});
						this.setState({from_long: formdata.e_start_long});


						if(Props.eventdetail[0].selecteddriver!==null && Props.eventdetail[0].selecteddriver.length > 0){
							if(Props.eventdetail[0].selecteddriver[0].value!== '' && Props.eventdetail[0].selecteddriver.value!== null){
								this.setState({ selectedDriver : Props.eventdetail[0].selecteddriver[0]})
								}
						}
						if(Props.eventdetail.length > 0){
							if(Props.eventdetail[0].driverlist!== '' && Props.eventdetail[0].driverlist!== null){
								this.setState({ driverlist : Props.eventdetail[0].driverlist})
							}
						}

			
			            if(Props.eventdetail[0].selectedbus!==null && Props.eventdetail[0].selectedbus.length > 0){
							if(Props.eventdetail[0].selectedbus[0].value!== '' && Props.eventdetail[0].selectedbus.value!== null){
								this.setState({ selectedBus : Props.eventdetail[0].selectedbus[0]})
								}
						}

						if(Props.eventdetail.length > 0){
							if(Props.eventdetail[0].buslist!== '' && Props.eventdetail[0].buslist!== null){
								this.setState({ buslist : Props.eventdetail[0].buslist})
							}
						}


					   if(Props.eventdetail[0].selectedgroup!==null &&Props.eventdetail[0].selectedgroup.length > 0){
							if(Props.eventdetail[0].selectedgroup[0].value!== '' && Props.eventdetail[0].selectedgroup.value!== null){
								this.setState({ selectedGroup : Props.eventdetail[0].selectedgroup[0]})
								}
						}

						if(Props.eventdetail.length > 0){
							if(Props.eventdetail[0].grouplist!== '' && Props.eventdetail[0].grouplist!== null){
								this.setState({ grouplist : Props.eventdetail[0].grouplist})
							}
						}

						 if(Props.eventdetail[0].selectedhelper!== null && Props.eventdetail[0].selectedhelper.length > 0){
							if(Props.eventdetail[0].selectedhelper[0].value!== '' && Props.eventdetail[0].selectedhelper.value!== null){
								this.setState({ selectedHelper : Props.eventdetail[0].selectedhelper[0]})
								}
						}

						if(Props.eventdetail.length > 0){
							if(Props.eventdetail[0].helperlist!== '' && Props.eventdetail[0].helperlist!== null){
								this.setState({ helperlist : Props.eventdetail[0].helperlist})
							}
						}
						
						
					}
    		}
    	}
    	if(Props.eventedit !== this.props.eventedit){
    		if(Object.keys(Props.eventedit).length > 0){
				if(Props.eventedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.eventedit[0].eventlist;
				if(formpayload.event_status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({event_name:formpayload.event_name});
				this.setState({event_description: formpayload.event_description});
				this.setState({event_location: formpayload.event_location});
				this.setState({event_start_date: formpayload.event_start_date});
				this.setState({event_end_date: formpayload.event_end_date});

				this.setState({event_type:formpayload.event_type});
						this.setState({e_start_point: formpayload.e_start_point});
						this.setState({e_end_point: formpayload.e_end_point});
						this.setState({no_of_passengers: formpayload.no_of_passengers});
						/*this.setState({assign_route: formpayload.assign_route});
						this.setState({assign_group:formpayload.assign_group});
						this.setState({assign_bus: formpayload.assign_bus});
						this.setState({assign_driver: formpayload.assign_driver});*/

						this.setState({to_long: formpayload.e_end_long});
						this.setState({to_lat: formpayload.e_end_lat});
						this.setState({from_lat: formpayload.e_start_lat});
						this.setState({from_long: formpayload.e_start_long});
				



               		$('.success_message').html('<div class="status_sucess"><h3>'+ Props.eventedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    				}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.eventedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
			}
    	}
		
    }

   setselectedvalue(selectsers){
    const routeArray = selectsers.map ((routelist, index) => ({
     id: index,
     name: routelist,
    }));
    // Dynamically create select list
    let routes = [];
    routeArray.map(item =>
    routes.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedRoute : routes})
  }

   setselecteddriver(selectsers){
    const driverArray = selectsers.map ((driverlist, index) => ({
     id: index,
     name: driverlist,
    }));
    // Dynamically create select list
    let drivers = [];
    driverArray.map(item =>
    drivers.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedDriver : drivers})
  }

   setselectedbus(selectsers){
    const busArray = selectsers.map ((buslist, index) => ({
     id: index,
     name: buslist,
    }));
    // Dynamically create select list
    let buses = [];
    busArray.map(item =>
    buses.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedBus : buses})
  }
   handleChangeDriver = selectedDriver => {
      this.setState({ selectedDriver});
      this.setState({ drivervalue : selectedDriver.value });
      if(selectedDriver.value === ''){
        $('.errorassign_driver').html('<span class="errorspan">Please select the field</span>');
      }
   }

   handleChangeBus = selectedBus => {
      this.setState({ selectedBus});
      this.setState({ busvalue : selectedBus.value });
      if(selectedBus.value === ''){
        $('.errorassign_bus').html('<span class="errorspan">Please select the field</span>');
      }
   }


    handleChangeGroup = selectedGroup => {
      this.setState({ selectedGroup});
      this.setState({ groupvalue : selectedGroup.value });
      if(selectedGroup.value === ''){
        $('.errorassign_group').html('<span class="errorspan">Please select the field</span>');
      }
   }


   handleChangeHelper = selectedHelper => {
      this.setState({ selectedHelper});
      this.setState({ helpervalue : selectedHelper.value });
      if(selectedHelper.value === ''){
        $('.errorassign_helper').html('<span class="errorspan">Please select the field</span>');
      }
   }

  handleChangeDeleteroute = (e) => {
	e.preventDefault();
    console.log(e.target.id);
	this.setState({
	  deleteRecord: e.target.id
	});
	console.log('datafilesss', e.target.id);
	const eventId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
	var qs = require('qs');
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),	
				event_id: eventId,
				id:  e.target.id
			};
	axios.post(apiUrl+"event/eventassignroutedelete",qs.stringify(postObject)).then(res => {
		alert("Assigned Stop Deleted Successfully.");
		window.location.reload();
	  });
  };

	
  render() {

  	const {selectedOption, selectedRoute,stop_lists, selectedDriver, selectedBus, selectedGroup,selectedHelper} = this.state;
  	console.log(this.state.event_type)
	let stops = '';
  	if(stop_lists !== undefined){
		 stops = stop_lists.map((values, index) =>{
			return<li>{values.assign_stop_place_name}<span className="remove" id={values.id}  onClick={this.handleChangeDeleteroute}>X</span></li>	
		 });
		 
	}  	

    const driverArray = this.state.driverlist.map ((driverlist, index) => ({
	id: index,
	name: driverlist,
	}));
	// Dynamically create select list
	let drivers = [];
    driverArray.map(item =>
    drivers.push({ label: item.name.label, value: item.name.value }),
    );


    const busArray = this.state.buslist.map ((buslist, index) => ({
	id: index,
	name: buslist,
	}));
	// Dynamically create select list
	let buses = [];
    busArray.map(item =>
    buses.push({ label: item.name.label, value: item.name.value }),
    );


     const groupArray = this.state.grouplist.map ((grouplist, index) => ({
	id: index,
	name: grouplist,
	}));
	// Dynamically create select list
	let groups = [];
    groupArray.map(item =>
    groups.push({ label: item.name.label, value: item.name.value }),
    );


    const helperArray = this.state.helperlist.map ((helperlist, index) => ({
	id: index,
	name: helperlist,
	}));
	// Dynamically create select list
	let helpers = [];
    helperArray.map(item =>
    helpers.push({ label: item.name.label, value: item.name.value }),
    );

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="event" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Event Name:</label>
				    <input type="text" name="event_name" onChange={this.handleInputChange} className="form-control" value={this.state.event_name} />
					<div className="errorevent_name"></div>
				</div>
				
				<div className="form-group">
					<label>Event Start Date:</label>
				    <input type="date" name="event_start_date" onChange={this.handleInputChange} className="form-control" value={this.state.event_start_date} />
					<div className="errorevent_start_date"></div>
				</div>


				<div className="form-group">
					<label>Event Start Location:</label>
				    <input id="from_loc" placeholder="Enter a Start location" type="text" name="e_start_point" onChange={this.handleInputChange} className="form-control" value={this.state.e_start_point} />
					<div className="errore_start_point	"></div>
				</div>

				<div className="form-group">					
					<label>Event Description:</label>
						<input type="text" name="event_description" onChange={this.handleInputChange} className="form-control" value={this.state.event_description} />
					<div className="errorevent_description"></div>
				</div>

				<div className="form-group">
					<label>Event Type : </label>
				<Select 
                         options={this.state.eventtype}  
                         value={this.state.event_type?this.state.event_type:{ value: '0', label: 'Select Type' }}
                         onChange={this.onValueChangeposition}
                         placeholder="Select Type" />

					<div className="errorevent_type	"></div>
				</div>
			
				<div className="form-group">					
					<label>Assign Group : </label>
					   <Select 
                         options={groups}  
                         value={selectedGroup?selectedGroup:{ value: '0', label: 'Select Group' }}
                         onChange={this.handleChangeGroup}
                         placeholder="Select Group" />
					<div className="errorassign_group"></div>
	          </div> 


	          <div className="form-group">					
					<label>Assign Helper : </label>
					   <Select 
                         options={helpers}  
                         value={selectedHelper?selectedHelper:{ value: '0', label: 'Select Helper' }}
                         onChange={this.handleChangeHelper}
                         placeholder="Select Helper" />
					<div className="errorassign_helper"></div>
	          </div> 

	          <div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
				
			</div>
			<div className="form-right">	
			
			<div className="form-group">
					<label>Event Place:</label>
						<input id="place_event" placeholder="Enter a Place" type="text" name="event_location" onChange={this.handleInputChange} className="form-control" value={this.state.event_location} />
					<div className="errorevent_location"></div>
				</div>


				<div className="form-group">
					<label>Event End Date:</label>
				    <input type="date" name="event_end_date" onChange={this.handleInputChange} className="form-control" value={this.state.event_end_date} />
					<div className="errorevent_end_date"></div>
				</div>
				
				<div className="form-group">
					<label>Event End Location:</label>
				    <input id="to_loc" placeholder="Enter a End location" type="text" name="e_end_point" onChange={this.handleInputChange} className="form-control" value={this.state.e_end_point} />
					<div className="errore_end_point	"></div>
				</div>

				<div className="form-group">
					<label>No of People:</label>
				    <input type="number" name="no_of_passengers" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_passengers} />
					<div className="errorno_of_passengers	"></div>
				</div>


				<div className="form-group">					
					<label>Assign Bus : </label>
						<Select 
                         options={buses}  
                         value={selectedBus?selectedBus:{ value: '0', label: 'Select Bus' }}
                         onChange={this.handleChangeBus}
                         placeholder="Select Bus" />
					<div className="errorassign_bus"></div>
				</div>


			   <div className="form-group">					
					<label>Assign Driver : </label>
						<Select 
                         options={drivers}  
                         value={selectedDriver?selectedDriver:{ value: '0', label: 'Select Driver' }}
                         onChange={this.handleChangeDriver}
                         placeholder="Select Driver" />
					<div className="errorassign_driver"></div>
				</div>

				<div className="form-group">
					<label>Assign Stop:</label>
					
				    <input id="assign_stop" placeholder="Add Stop Point" autoComplete ="off" type="text" name="assign_stop" onChange={this.handleInputChange} className="form-control" value={this.state.assign_stop} />
					<ul className="stops-list">{stops}</ul>
			
					<div className="errorassign_route"></div>
				</div>

			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	eventdetail  : state.eventdetail,
    eventedit: state.eventedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
	getEventDetail: (eventId, adminId) =>{
		dispatch({ type: GET_EVENTDETAIL,eventId, adminId});
	 },
     getEditEvent: (formPayload) =>{
       dispatch({ type: GET_EDITEVENT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));