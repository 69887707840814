/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDROUTE, SET_ADDROUTE, GET_ROUTEDETAIL ,SET_ROUTEDETAIL,GET_ROUTELIST,SET_ROUTELIST,GET_EDITROUTE,SET_EDITROUTE,GET_DELETEROUTE, SET_DELETEROUTE } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/* Route list*/
export const watchGetRouteList = function* () {
  yield takeEvery(GET_ROUTELIST, workerGetRouteList);
}

function* workerGetRouteList({ formVal }) {
  try {
      
 const result = yield call(getRouteList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ROUTELIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getRouteList(formVal) {
   return Axios.post(apiUrl+'route/list', formVal);
} 



/*save the Route*/

export const watchGetAddRoute = function* () {
  yield takeEvery(GET_ADDROUTE, workerGetAddRoute);
}

function* workerGetAddRoute({ formPayload }) {
  try {
      
 const result = yield call(getAddRoute, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDROUTE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddRoute(formPayload) {
 //console.log('kskdksdsd',formPayload);
   return Axios.post(apiUrl+'route/add', formPayload);
} 


/*edit the Route*/

export const watchGetEditRoute = function* () {
  yield takeEvery(GET_EDITROUTE, workerGetEditRoute);
}

function* workerGetEditRoute({ formPayload }) {
  try {
      
 const result = yield call(getEditRoute, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITROUTE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditRoute(formPayload) {
   return Axios.post(apiUrl+'route/edit', formPayload);
}


/*delete the Route*/

export const watchGetDeleteRoute = function* () {
  yield takeEvery(GET_DELETEROUTE, workerGetDeleteRoute);
}

function* workerGetDeleteRoute({ formPayload }) {
  try {
      
 const result = yield call(getDeleteRoute, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEROUTE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteRoute(formPayload) {
  return Axios.post(apiUrl+'route/delete', formPayload);
} 



/*get Route detail*/

export const watchGetRouteDetail = function* () {
  yield takeEvery(GET_ROUTEDETAIL, workerGetRouteDetail);
}

function* workerGetRouteDetail({ routeId }) {
  var url = apiUrl+'route/routedetail?id='+routeId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ROUTEDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  
