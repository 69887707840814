import { SET_ADDCAR } from '../actions';

const caradd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDCAR:
      return [...action.value];  
    default: return state;
  }
}

export default caradd;
