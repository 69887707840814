import { SET_GROUPDETAIL } from '../actions';

const groupdetail = (state = [], action) => {
  switch (action.type) {
	case SET_GROUPDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export default groupdetail;
