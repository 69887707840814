import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
// import DateTimePicker from 'react-datetime-picker';
// //import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import Geocode from "react-geocode";

import {GET_ADDEVENT }  from '../../actions'; 

class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			event_name:'',			
			event_description:'',
			event_location:'',
			event_start_date:'',
			event_end_date:'',
			event_status:'',
			event_type:'',
			e_start_point:'',
			e_end_point:'',
			no_of_passengers:'',
			// assign_route:'',
			// assign_group:'',
			// assign_bus:'',
			// assign_driver:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		const google = window.google;
		const callfunc = this;
			   if(name == 'e_start_point'){
					   var input = document.getElementById('from_loc');
					   var autocomplete = new google.maps.places.Autocomplete(input);
					   autocomplete.addListener('place_changed',function() {
					   document.getElementById("location-error").style.display = 'none';
					   var place = autocomplete.getPlace();
					   var locationAddress1 = place.name;
					   var locationAddress = place.formatted_address;
					   var fullplace = place.name+','+locationAddress;
					   callfunc.setState({e_start_point: locationAddress})
					   callfunc.getgoecoder(locationAddress,input.name);
					   callfunc.setState({fulldroploc : fullplace});
					   });
			   }

			   if(name == 'e_end_point'){
				
				var input = document.getElementById('to_loc');
				var autocomplete = new google.maps.places.Autocomplete(input);
				autocomplete.addListener('place_changed',function() {
				document.getElementById("location-error").style.display = 'none';
				var place = autocomplete.getPlace();
				var locationAddress1 = place.name;
				var locationAddress = place.formatted_address;
				var fullplace = place.name+','+locationAddress;
				callfunc.setState({e_end_point: locationAddress})
				callfunc.getgoecoder(locationAddress,input.name);
				callfunc.setState({fulldroploc : fullplace});
				});
		}

		if(name == 'event_location'){
			var input = document.getElementById('place_event');
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.addListener('place_changed',function() {
			document.getElementById("location-error").style.display = 'none';
			var place = autocomplete.getPlace();
			var locationAddress1 = place.name;
			var locationAddress = place.formatted_address;
			var fullplace = place.name+','+locationAddress;
			callfunc.setState({event_location: locationAddress})
			callfunc.getgoecoder(locationAddress,input.name);
			callfunc.setState({fulldroploc : fullplace});
			});
	}


			
    }

			
	getgoecoder(input, name){
		$('#from_loc').val('');

		$('#to_loc').val('');

		$('#place_event').val('');
			
		if(name == 'e_start_point'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;
				this.setState({from_long : lng, from_lat: lat, e_start_point:response.results[0].formatted_address});
				
				document.getElementById('from_loc').value = response.results[0].formatted_address;
				$('#from_loc').val(response.results[0].formatted_address);					  
			},
			error => {
				console.error(error);
			  }
			);
		}else if(name == 'e_end_point'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					to_long : lng,
					to_lat: lat,
					e_end_point: response.results[0].formatted_address
				});	
				
				document.getElementById('to_loc').value = response.results[0].formatted_address;
				$('#to_loc').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}else if(name == 'event_location'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
				
					event_location: response.results[0].formatted_address
				});	
				
				document.getElementById('place_event').value = response.results[0].formatted_address;
				$('#place_event').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}

	}
   keyPressed(event) {
	 if (event.key === "Enter") {
	 localStorage.setItem('setkeyword',event.target.value);
	  setTimeout(() => {
			$('#clickToggle').trigger('click');
			document.getElementById("from_loc").value = "";
		 }, 500);
	}
	}

	componentDidMount() {
		document.title = PageTitle('Event Add');
    }

	onValueChangeposition = (event) => {
		if(event.target.value != ''){
			var event_type = event.target.value;
		}
	localStorage.setItem('event_type', event_type);
	
	this.setState({
		event_type: event.target.value
		});
		
	  }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					event_name:formPayload.event_name,			
					event_description:formPayload.event_description,
					event_location:formPayload.event_location,
					event_start_date:formPayload.event_start_date,
					event_end_date:formPayload.event_end_date,
					event_type:formPayload.event_type,
					e_start_point:formPayload.e_start_point,
					e_end_point:formPayload.e_end_point,
					to_long:formPayload.to_long,
					to_lat:formPayload.to_lat,
					from_lat:formPayload.from_lat,
					from_long:formPayload.from_long,
					no_of_passengers:formPayload.no_of_passengers,
					// assign_route:formPayload.assign_route,
					// assign_group:formPayload.assign_group,
					// assign_bus:formPayload.assign_bus,
					// assign_driver:formPayload.assign_driver,
					event_status:status,
					
				};

				console.log('postObject',postObject);
				this.props.getAddEvent(qs.stringify(postObject));
			}
	}

	
	 
	validateForm() {
		const {event_name,assign_route,assign_group,assign_bus,assign_driver,event_description,no_of_passengers,e_end_point,event_location,event_start_date,event_end_date,event_type,e_start_point} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!event_name) {
			formIsValid = false;
			$('.errorevent_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_name){
			$('.errorevent_name').html('');
		}
		
		if (!event_description) {
			formIsValid = false;
			$('.errorevent_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_description){
			$('.errorevent_description').html('');
		}		
		

		if (!event_location) {
			formIsValid = false;
			$('.errorevent_location').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_location){
			$('.errorevent_location').html('');
		}

		if (!event_start_date) {
			formIsValid = false;
			$('.errorevent_start_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_start_date){
			$('.errorevent_start_date').html('');
		}

		if (!event_end_date) {
			formIsValid = false;
			$('.errorevent_end_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_end_date){
			$('.errorevent_end_date').html('');
		}

		if (!event_type) {
			formIsValid = false;
			$('.errorevent_type').html('<span class="errorspan">Please fill the field</span>');
		}else if(event_type){
			$('.errorevent_type').html('');
		}


		if (!e_start_point) {
			formIsValid = false;
			$('.errore_start_point').html('<span class="errorspan">Please fill the field</span>');
		}else if(e_start_point){
			$('.errore_start_point').html('');
		}

		if (!no_of_passengers) {
			formIsValid = false;
			$('.errorno_of_passengers').html('<span class="errorspan">Please fill the field</span>');
		}else if(no_of_passengers){
			$('.errorno_of_passengers').html('');
		}


		// if (!assign_route) {
		// 	formIsValid = false;
		// 	$('.errorassign_route').html('<span class="errorspan">Please fill the field</span>');
		// }else if(assign_route){
		// 	$('.errorassign_route').html('');
		// }


		// if (!assign_group) {
		// 	formIsValid = false;
		// 	$('.errorassign_group').html('<span class="errorspan">Please fill the field</span>');
		// }else if(assign_group){
		// 	$('.errorassign_group').html('');
		// }


		// if (!assign_bus) {
		// 	formIsValid = false;
		// 	$('.errorassign_bus').html('<span class="errorspan">Please fill the field</span>');
		// }else if(assign_bus){
		// 	$('.errorassign_bus').html('');
		// }


		// if (!assign_driver) {
		// 		formIsValid = false;
		// 		$('.errorassign_driver').html('<span class="errorspan">Please fill the field</span>');
		// 	}else if(assign_driver){
		// 		$('.errorassign_driver').html('');
		// 	}

		


		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){

   	if(Props.eventadd !== this.props.eventadd){
    		if(Object.keys(Props.eventadd).length > 0){
				this.setState({Loading:false});

					if(Props.eventadd[0].status === "success"){

						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.eventadd[0].message+'</h3></div>');
						this.setState({
							event_name:'',			
							event_description:'',
							event_location:'',
							event_start_date:'',
							event_end_date:'',
							event_status:'',
							event_type:'',
							e_start_point:'',
							e_end_point:'',
							no_of_passengers:'',
							// assign_route:'',
							// assign_group:'',
							// assign_bus:'',
							// assign_driver:'',
							selectedvalue: ''
						});
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+ Props.eventadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }

	// onChangedatetime1 = (event_start_date) => { 
	// 	console.log('Timeee',event_start_date)
	// 	this.setState({ event_start_date })
	// 	var event_start_date =  this.convert2(event_start_date);
	// 	//localStorage.setItem('accident_time', event_start_date)
	// }
	
  render() {
  	  const {selectedOption} = this.state;
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="event" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Event</h4>				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Event Name:</label>
				    <input type="text" name="event_name" onChange={this.handleInputChange} className="form-control" value={this.state.event_name} />
					<div className="errorevent_name"></div>
				</div>
				{/*<DatePicker
				selected={this.state.event_start_date}
				showTimeSelect
				onChange={this.onChangedatetime1}
				showTimeSelect
				dateFormat="dd/MM/yyyy | HH:mm"
				/>*/}
				<div className="form-group">
					<label>Event Start Date:</label>
				    <input type="date" name="event_start_date" onChange={this.handleInputChange} className="form-control" value={this.state.event_start_date} />
					<div className="errorevent_start_date"></div>
				</div>

				<div className="form-group">
					<label>Event Start Location:</label>
				    <input id="from_loc" placeholder="Enter a Start location" type="text" name="e_start_point" onChange={this.handleInputChange} className="form-control" value={this.state.e_start_point} />
					<div className="errore_start_point	"></div>
				</div>

				<div className="form-group">
					<label>Event Type : </label>
				    <select id="lang" onChange={this.onValueChangeposition.bind(this)} value={this.state.event_type}>
				<option value="select">Select Event Type</option>
				<option value="Trekking">Trekking</option>
				<option value="Trip">Trip</option>
				<option value="School">School</option>
				</select>	
					<div className="errorevent_type	"></div>
				</div>
			
				<div className="form-group">					
					<label>Event Description:</label>
						<textarea type="text" name="event_description" onChange={this.handleInputChange} className="form-control" value={this.state.event_description} />
					<div className="errorevent_description"></div>
				</div>


			{/*	<div className="form-group">					
					<label>Assign Route : </label>
						<input type="text" name="assign_route" onChange={this.handleInputChange} className="form-control" value={this.state.assign_route} />
					<div className="errorassign_route"></div>
				</div>


				<div className="form-group">					
					<label>Assign Group : </label>
						<input type="text" name="assign_group" onChange={this.handleInputChange} className="form-control" value={this.state.assign_group} />
					<div className="errorassign_group"></div>
	          </div>  */}

				
				
			</div>
			<div className="form-right">	
			
			<div className="form-group">
					<label>Event Place:</label>
						<input id="place_event" placeholder="Enter a Place" type="text" name="event_location" onChange={this.handleInputChange} className="form-control" value={this.state.event_location} />
					<div className="errorevent_location"></div>
				</div>


				<div className="form-group">
					<label>Event End Date:</label>
				    <input type="date" name="event_end_date" onChange={this.handleInputChange} className="form-control" value={this.state.event_end_date} />
					<div className="errorevent_end_date"></div>
				</div>
				
				<div className="form-group">
					<label>Event End Location:</label>
				    <input id="to_loc" placeholder="Enter a End location" type="text" name="e_end_point" onChange={this.handleInputChange} className="form-control" value={this.state.e_end_point} />
					<div className="errore_end_point	"></div>
				</div>

				<div className="form-group">
					<label>No of People:</label>
				    <input type="number" name="no_of_passengers" onChange={this.handleInputChange} className="form-control" value={this.state.no_of_passengers} />
					<div className="errorno_of_passengers	"></div>
				</div>
				

				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>

			{/*		<div className="form-group">					
					<label>Assign Bus : </label>
						<input type="text" name="assign_bus" onChange={this.handleInputChange} className="form-control" value={this.state.assign_bus} />
					<div className="errorassign_bus"></div>
				</div>


				<div className="form-group">					
					<label>Assign Driver : </label>
						<input type="text" name="assign_driver" onChange={this.handleInputChange} className="form-control" value={this.state.assign_driver} />
					<div className="errorassign_driver"></div>
				</div>  */}
			</div>	
			</div>		

			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									<span className="load-data"></span> 
									}Submit
			   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	eventadd: state.eventadd
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	 getAddEvent: (formPayload) =>{
       dispatch({ type: GET_ADDEVENT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Add));