import { SET_EDITHELPER } from '../actions';

const helperedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITHELPER:
      return [...action.value];  
    default: return state;
  }
}

export default helperedit;
