import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { GET_TEMPLATELIST, GET_TEMPLATEDELETE } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      admin_id: localStorage.getItem("admin_id")
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
      };

    this.props.getTemplateList(qs.stringify(postobject));

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Templates');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    this.props.getFontList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getTemplateList(qs.stringify(postObject));
        
    }

   componentWillReceiveProps(Props){

    if (Object.keys(Props.templatelist).length > 0) {
      if (Props.templatelist[0]["status"] === "success") {
       /* this.setState({
          fontlist: Props.fontlist[0]["fontlist"],
        });*/
         this.setState({ totalRecord : Props.templatelist[0].records_count, templatelist: Props.templatelist[0] }, function() {
            this.templatelist();
          });
      }
    }
    if (Props.templatedelete!== this.props.templatedelete) {
      if (Props.templatedelete[0]["status"] === "success") {
          $('.success_message').html('<div class="status_sucess"><h3>'+ Props.templatedelete[0]["message"]+'</h3></div>');
           setTimeout(
          function() {
          $('.success_message').html('');
          }
          .bind(this),
          50000
          );


          this.setState({templatelist: Props.templatedelete[0]}, function() {
            this.templatelist();
          });
       
          }
          //window.location.reload(); 
      }

   }

deletetemplate(id){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          email_id  : id,
          admin_id  : admin_id
        };
      this.props.getTemplateDelete(qs.stringify(postObject));
}
templatelist() {
    var templatelist = this.state.templatelist;
    if (templatelist != "undefined" && templatelist != null) {
      if (templatelist.status == "success") {
        if (Object.keys(templatelist).length > 0) {
          const templatelistDetails = templatelist.templatelist.map(
            (templatelist, Index) => {
              let sno = Index+1;
              return (
                <tr key={templatelist.email_id}>
                <td>{sno}</td>
                  <td>
                  {templatelist.email_name}
                  </td>
                  <td>
                   <Link to={{ pathname: '/templates-edit/'+templatelist.email_id}} className="templateedit" title="edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                   <Link onClick={this.deletetemplate.bind(this, templatelist.email_id)} className="templateedit" title="delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>

                  </td>
                </tr>
              );
            }
          );
          return templatelistDetails ;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="templates" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">
             <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Templates</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="add" href='/template/add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Template Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.templatelist()}</tbody>
                </Table>
                 <div className="text-center">
         {/*   <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />*/}
          </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    templatelist: state.templatelist,
    templatedelete: state.templatedelete
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTemplateList: (formVal) =>{
       dispatch({ type: GET_TEMPLATELIST , formVal});
    },
    getTemplateDelete: (formPayload) => {
      dispatch({type: GET_TEMPLATEDELETE, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));