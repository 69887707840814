import { SET_SETTINGDATA } from '../actions';

const settings = (state = [], action) => {
  switch (action.type) {
	case SET_SETTINGDATA:
      return [...action.value];  
    default: return state;
  }
}

export default settings;
