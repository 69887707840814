import { SET_EDITCOMMENT } from '../actions';

const commentedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITCOMMENT:
      return [...action.value];  
    default: return state;
  }
}

export default commentedit;
