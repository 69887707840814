/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDVEHICLE, SET_ADDVEHICLE, GET_VEHICLELIST,SET_VEHICLELIST,GET_EDITVEHICLE,SET_EDITVEHICLE,GET_DELETEVEHICLE, SET_DELETEVEHICLE, GET_VEHICLEDETAIL, SET_VEHICLEDETAIL } from '../actions';
import {apiUrl } from "../components/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


export const watchGetVehicleDetail = function* () {
  yield takeEvery(GET_VEHICLEDETAIL, workerGetVehicleDetail);
}

function* workerGetVehicleDetail({ VehicleId }) {

  var url = apiUrl+'vehicle/vehicledetail?id='+VehicleId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_VEHICLEDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}


/*get vehicle list*/

export const watchGetVehicleList = function* () {
  yield takeEvery(GET_VEHICLELIST, workerGetVehicleList);
}

function* workerGetVehicleList({ formVal }) {
  try {
      
 const result = yield call(getVehicleList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_VEHICLELIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getVehicleList(formPayload) {
   return Axios.post(apiUrl+'vehicle/list', formPayload);
}  

/*save the vehicle*/

export const watchGetAddVehicle = function* () {
  yield takeEvery(GET_ADDVEHICLE, workerGetAddVehicle);
}

function* workerGetAddVehicle({ formPayload }) {
  try {
      
 const result = yield call(getAddVehicle, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDVEHICLE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddVehicle(formPayload) {
   return Axios.post(apiUrl+'vehicle/add', formPayload);
} 


/*edit the vehicle*/

export const watchGetEditVehicle = function* () {
  yield takeEvery(GET_EDITVEHICLE, workerGetEditVehicle);
}

function* workerGetEditVehicle({ formPayload }) {
  try {
      
 const result = yield call(getEditVehicle, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITVEHICLE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditVehicle(formPayload) {
   return Axios.post(apiUrl+'vehicle/edit', formPayload);
} 


/*delete the vehicle*/

export const watchGetDeleteVehicle = function* () {
  yield takeEvery(GET_DELETEVEHICLE, workerGetDeleteVehicle);
}

function* workerGetDeleteVehicle({ formPayload }) {
  try {
      
 const result = yield call(getDeleteVehicle, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEVEHICLE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteVehicle(formPayload) {
   return Axios.post(apiUrl+'vehicle/delete', formPayload);
} 