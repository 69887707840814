import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { GET_FONTLIST, GET_DELETEFONT } from '../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:''
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
      };

    this.props.getFontList(qs.stringify(postobject));

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Fonts');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    this.props.getFontList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getFontList(qs.stringify(postObject));
        
    }

   componentWillReceiveProps(Props){

    if (Object.keys(Props.fontlist).length > 0) {
      if (Props.fontlist[0]["status"] === "success") {
       /* this.setState({
          fontlist: Props.fontlist[0]["fontlist"],
        });*/
         this.setState({ totalRecord : Props.fontlist[0].records_count, fontlist: Props.fontlist[0]["fontlist"] }, function() {
            this.fontlist();
          });
      }
    }
    if (Props.fontdelete!== this.props.fontdelete) {
      if (Props.fontdelete[0]["status"] === "success") {
          this.setState({
          fontlist: Props.fontdelete[0]["fontlist"],
          });
          }
          window.location.reload(); 
      }

   }

deletevehicle(id){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          font_id  :id,
          admin_id  : admin_id
        };
      this.props.getDeleteFont(qs.stringify(postObject));
}
  fontlist() {
    var fontlist = this.props.fontlist[0];
    if (fontlist != "undefined" && fontlist != null) {
      if (fontlist.status == "success") {
        if (Object.keys(fontlist).length > 0) {
          const fontlistDetails = fontlist.fontlist.map(
            (fontlist, Index) => {
              let sno = Index+1;
              if(fontlist.tf_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={fontlist.tf_id}>
                <td>{sno}</td>
                  <td>
                  {fontlist.tf_name}
                  </td>
                  <td>{status}</td>
                  <td>
                  {/* <Link to={{ pathname: '/font/edit/'+fontlist.tf_id}} className="" title="edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>*/}
                     <Link onClick={this.deletevehicle.bind(this,fontlist.tf_id)} className="" title="delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return fontlistDetails ;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="fonts" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>Fonts</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
              <div className="add_button">  
                    <a className="add" href='/font-add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Font Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.fontlist()}</tbody>
                </Table>
                 <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    fontlist: state.fontlist,
    fontdelete: state.fontdelete
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFontList: (formVal) =>{
       dispatch({ type: GET_FONTLIST , formVal});
    },
    getDeleteFont: (formPayload) => {
      dispatch({type: GET_DELETEFONT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));