import { SET_DELETEGROUP } from '../actions';

const deletegroup = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEGROUP:
      return [...action.value];  
    default: return state;
  }
}

export default deletegroup;
