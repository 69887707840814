import login from "./login";
import settings from "./settings";
import changepassword from "./changepassword";
import updatesetting from './updatesetting';
import forgetpassword from './forgetpassword';
import profile from './profile';
import updateprofile from './updateprofile';
import vehicleadd from './vehicleadd';
import vehiclelist from './vehiclelist';
import vehicleedit from './vehicleedit';
import vehicletype from './vehicletype';

import companylist from './companylist';
import companyadd from './companyadd';
import companyedit from './companyedit';

import caradd from './caradd';
import carlist from './carlist';
import caredit from './caredit';
import ongoingrideview from './ongoingrideview';
import deletevehicle from './deletevehicle';
import deletecar from './deletecar';
import priorityupdate from './priorityupdate';
import bookinghistory from './bookinghistory';
import fontlist from './fontlist';
import fontname from './fontname';
import fontadd from './fontadd';
import userlist from './userlist';
import changestatus from './changestatus';
import exportuser from './exportuser';
import fontdelete from './fontdelete';
import driverdelete from './driverdelete';
import useredit from './useredit';
import userimport from './userimport';
import driverlist from './driverlist';
import driverstatus from './driverstatus';
import exportdriver from './exportdriver';
import locateddrivers from './locateddrivers';
import driveredit from './driveredit';
import ongoingride from './ongoingride';
import completedride from './completedride';
import incompleteride from './incompleteride';
import userdetails from './userdetails';
import driverdetail from './driverdetail';
import vehicledetail from './vehicledetail';
import cardetail from './cardetail';
import useradd from './useradd';
import driveradd from './driveradd';
import driverdropdownlist from './driverdropdownlist';
import driverassigned from './driverassigned';

import eventlist from './eventlist';
import eventadd from './eventadd';
import eventedit from './eventedit';
import eventdetail from './eventdetail';
import deleteevent from './deleteevent';


import routelist from './routelist';
import routeadd from './routeadd';
import routeedit from './routeedit';
import routedetail from './routedetail';
import deleteroute from './deleteroute';
import templatelist from './templatelist';
import templatedetail from './templatedetail';
import templateadd from './templateadd';
import templatedelete from './templatedelete';
import templateedit from './templateedit';


import helperlist from './helperlist';
import helperadd from './helperadd';
import helperedit from './helperedit';
import helperdetail from './helperdetail';
import deletehelper from './deletehelper';

import grouplist from './grouplist';
import groupadd from './groupadd';
import groupedit from './groupedit';
import groupdetail from './groupdetail';
import deletegroup from './deletegroup';
import groupuserlist from './groupuserlist';

import commentadd from './commentadd';
import commentlist from './commentlist';
import commentdetail from './commentdetail';
import commentedit from './commentedit';
import commentdelete from './commentdelete';
import domainlist from './domainlist';

import { combineReducers } from "redux";


const rootReducer = combineReducers({
  login: login,
  settings      : settings,
  updatesetting : updatesetting,
  changepassword: changepassword,
  forgetpassword: forgetpassword,
  profile       : profile,
  updateprofile : updateprofile,
  vehicleadd    : vehicleadd,
  vehiclelist   : vehiclelist,
  companylist   : companylist,
  companyadd   : companyadd,
  companyedit   : companyedit,
  vehicleedit   : vehicleedit,
  vehicletype   : vehicletype,
  caradd        : caradd,
  carlist       : carlist,
  caredit       : caredit,
  deletevehicle : deletevehicle,
  deletecar     : deletecar,
  priorityupdate     : priorityupdate,
  bookinghistory     : bookinghistory,
  fontlist           : fontlist,
  fontname           : fontname,
  fontadd            : fontadd,
  userlist			 : userlist,
  exportuser     : exportuser,
  fontdelete         : fontdelete,
  driverdelete         : driverdelete,
  useredit: useredit,
  userimport: userimport,
  driverlist: driverlist,
  driverdropdownlist: driverdropdownlist,
  driverassigned: driverassigned,
  driverstatus: driverstatus,
  exportdriver: exportdriver,
  locateddrivers: locateddrivers,
  driveredit: driveredit,
  ongoingride: ongoingride,
  ongoingrideview: ongoingrideview,
  completedride: completedride,
  incompleteride: incompleteride,
  userdetails: userdetails,
  driverdetail: driverdetail,
  vehicledetail: vehicledetail,
  cardetail    : cardetail,
  useradd      : useradd,
  driveradd      : driveradd,
  changestatus: changestatus,
  eventlist   : eventlist,
  eventadd   : eventadd,
  eventedit   : eventedit,
  deleteevent     : deleteevent,
  eventdetail    : eventdetail,
  routelist   :routelist,
  routeadd   : routeadd,
  routeedit   : routeedit,
  deleteroute     : deleteroute,
  routedetail    :routedetail,
    templatelist  : templatelist,
  templatedetail: templatedetail,
  templateadd   : templateadd,
  templatedelete : templatedelete,
  templateedit: templateedit,
  

  helperlist   : helperlist,
  helperadd   : helperadd,
  helperedit   : helperedit,
  deletehelper     : deletehelper,
  helperdetail    : helperdetail,

  grouplist   : grouplist,
  groupadd   : groupadd,
  groupedit   : groupedit,
  deletegroup     : deletegroup,
  groupdetail    : groupdetail,
  groupuserlist  : groupuserlist,

  commentadd   : commentadd,
  commentlist  : commentlist,
  commentdetail: commentdetail,
  commentedit  : commentedit,
  commentdelete : commentdelete,
  domainlist    : domainlist

});

export default rootReducer;
