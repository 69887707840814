import { SET_INCOMPLETERIDE } from '../actions';

const incompleteride = (state = [], action) => {
  switch (action.type) {
	case SET_INCOMPLETERIDE:
      return [...action.value];  
    default: return state;
  }
}

export default incompleteride;
