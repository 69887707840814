import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

import Geocode from "react-geocode";
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";


import {GET_EDITCOMMENT,GET_COMMENTDETAIL,GET_COMMENTLIST}  from '../../actions'; 

class Edit extends Component {
	
	constructor(props)
	{
		
		super(props);	
	    const commentId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
			comments:'',
			status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			comment_id: commentId,
			Loading:false,
			admin_id: localStorage.getItem('admin_id')

		};
		this.props.getCommentDetail(commentId);
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");
		

		

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
	
    }


	componentDidMount() {
		document.title = PageTitle('Comment Edit');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
			}
    }
	
	handleFormSubmit = () => {
		if(this.validateForm()){
			this.setState({Loading:true});
			var qs = require('qs');
			const formPayload = this.state;
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}
			
		     var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					comment_id : formPayload.comment_id,
					comments:formPayload.comments,
					status:status,
				};
				this.props.getEditComment(qs.stringify(postObject));
		
		}
}


	validateForm() {
		
		const {comments} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		  if (!comments) {
			formIsValid = false;
			$('.error_comments').html('<span class="errorspan">Please fill the field</span>');
		}else if(comments){
			$('.error_comments').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

    componentWillReceiveProps(Props){
    	console.log(Props)
   	if(Props.commentdetail !== this.props.commentdetail){
    		if(Object.keys(Props.commentdetail).length > 0){
				this.setState({Loading:false});
				//console.log(Props.routedetail);
					if(Props.commentdetail[0].status === "success"){

						const formdata = Props.commentdetail[0].commentdetail;
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}

						
						this.setState({comments:formdata.comments});
					}
    		}
    	}
    	if(Props.commentedit !== this.props.commentedit){
    		if(Object.keys(Props.commentedit).length > 0){
				if(Props.commentedit[0].status === "success"){
    			this.setState({ Loading: false });

    			const formpayload = Props.commentedit[0].commentlist;
				if(formpayload.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({comments:formpayload.comments});
					
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.commentedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.commentedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	
  render() {
  	  const {selectedOption} = this.state;
		//console.log('aafff',this.state);
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="comment" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			   <div className="form-group">
					<label>Comments:</label>
				    <input type="text" name="comments" onChange={this.handleInputChange} className="form-control" value={this.state.comments} />
					<div className="error_comments"></div>
				</div>
				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
					<div className=""></div>
				</div>
				
			</div>
			<div className="form-right">
			</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	commentdetail  : state.commentdetail,
    commentedit: state.commentedit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   
	getCommentDetail: (routeId) =>{
		dispatch({ type: GET_COMMENTDETAIL,routeId });
	 },
     getEditComment: (formPayload) =>{
       dispatch({ type: GET_EDITCOMMENT, formPayload});
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));