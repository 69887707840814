import { SET_ADDHELPER } from '../actions';

const helperadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDHELPER:
      return [...action.value];  
    default: return state;
  }
}

export default helperadd;
